import { AxiosResponse } from 'axios'
import { PaginatedResponse, ResponseOne } from '@/api/PaginatedResponse'
import {
  MarketplacePartnerDto,
  MarketplaceParams,
  MarketplaceAbilities,
  MarketplacePartnerRequest,
  MarketplacePartnerEmailTemplate,
  MarketplaceSubscriberDto,
  MarketplacePartnerEmailTemplateRequest,
} from '@/dto/Marketplace/Partner'
import client, { NoErrorAxiosRequestConfig } from '@/api/client'
import { MarketplaceTemplateDestination } from '@/enums/MarketplaceEnum'

const prefix = 'marketplace'

export function getMarketplaceAbilities(
  businessId: string,
): Promise<AxiosResponse<ResponseOne<MarketplaceAbilities>>> {
  return client.get(`${prefix}/businesses/${businessId}/partners/abilities`)
}

export function getMarketplacePartners(
  businessId: string,
  params?: MarketplaceParams,
): Promise<AxiosResponse<PaginatedResponse<MarketplacePartnerDto>>> {
  return client.get(`${prefix}/businesses/${businessId}/partners`, {
    params,
  })
}

export function getMarketplacePartner(
  businessId: string,
  partnerId: string,
): Promise<AxiosResponse<ResponseOne<MarketplacePartnerDto>>> {
  return client.get(`${prefix}/businesses/${businessId}/partners/${partnerId}`)
}

export function getMarketplaceTemplates(
  businessId: string,
  params?: MarketplaceParams,
): Promise<AxiosResponse<PaginatedResponse<MarketplacePartnerEmailTemplate>>> {
  return client.get(`${prefix}/businesses/${businessId}/templates`, {
    params,
  })
}

export function getMarketplacePartnerTemplates(
  partnerId: string,
  params?: Partial<MarketplaceParams>,
): Promise<AxiosResponse<PaginatedResponse<MarketplacePartnerEmailTemplate>>> {
  return client.get(`${prefix}/partners/${partnerId}/templates`, {
    params,
  })
}

export function createPartnerEmailTemplate(
  partnerId: string,
  request: MarketplacePartnerEmailTemplateRequest,
) {
  return client.post(`${prefix}/partners/${partnerId}/templates`, request)
}

export function updatePartnerEmailTemplate(
  partnerId: string,
  templateId: string,
  request: MarketplacePartnerEmailTemplateRequest,
) {
  return client.put(`${prefix}/partners/${partnerId}/templates/${templateId}`, request)
}

export function deletePartnerEmailTemplate(partnerId: string, templateId: string) {
  return client.delete(`${prefix}/partners/${partnerId}/templates/${templateId}`)
}

export function copyPartnerEmailTemplate(
  businessId: string,
  partnerId: string,
  templateId: string,
  destination: MarketplaceTemplateDestination,
) {
  return client.post(`${prefix}/partners/${partnerId}/templates/${templateId}/copy`, {
    business_id: businessId,
    destination,
  })
}

export function getPartnerEmailTemplate(
  partnerId: string,
  templateId: string,
): Promise<AxiosResponse<ResponseOne<MarketplacePartnerEmailTemplate>>> {
  return client.get(`${prefix}/partners/${partnerId}/templates/${templateId}`)
}

export function getMyMarketplacePartner(
  businessId: string,
): Promise<AxiosResponse<ResponseOne<MarketplacePartnerDto>>> {
  return client.get(`${prefix}/businesses/${businessId}/partners/me`, <NoErrorAxiosRequestConfig>{
    _noError: true,
  })
}

export function subscribeToPartner(businessId: string, partnerCode: string) {
  return client.post(`${prefix}/businesses/${businessId}/subscriptions`, {
    partner_code: partnerCode,
  })
}

export function createMyMarketplacePartner(
  businessId: string,
  payload: MarketplacePartnerRequest,
): Promise<AxiosResponse<ResponseOne<MarketplacePartnerDto>>> {
  return client.put(`${prefix}/businesses/${businessId}/partners/me`, payload)
}

export function updateMyMarketplacePartner(
  businessId: string,
  payload: MarketplacePartnerDto,
): Promise<AxiosResponse<ResponseOne<MarketplacePartnerDto>>> {
  return client.put(`${prefix}/businesses/${businessId}/partners/me`, payload)
}

export function getAllPartnerSubscribers(
  partnerId: string,
  params?: { page: number },
): Promise<AxiosResponse<PaginatedResponse<MarketplaceSubscriberDto>>> {
  return client.get(`${prefix}/partners/${partnerId}/subscribers`, {
    params,
  })
}

export function getAllPartnerTemplates(
  partnerId: string,
): Promise<AxiosResponse<PaginatedResponse<MarketplacePartnerEmailTemplate>>> {
  return client.get(`${prefix}/partners/${partnerId}/templates`, {
    params: {
      is_public: true,
      is_for_subscribers: true,
    },
  })
}

export function getFilterOptionsForPartners(
  businessId: string,
  params?: Partial<MarketplaceParams>,
): Promise<AxiosResponse<ResponseOne<{ title: string; id: string }[]>>> {
  return client.get(`${prefix}/businesses/${businessId}/partners/mini`, {
    params,
  })
}

// self service
// export function deleteMyMarketplacePartner(){ // greyed out in the docs - might not be implemented
//   // DELETE /partners/me
// }
