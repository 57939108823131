// Shared
export enum LeadTimelineDirectionEnum {
  OUTGOING = 1,
  INCOMING = 2,
}

export enum LeadTimelineEventEnum {
  CREATED = 'created',
  UPDATED = 'updated',
  DELETED = 'deleted',
  SCHEDULED = 'scheduled',
  EDITED = 'edited',
  MERGED = 'merged',
  ACTION = 'action',
  ERROR = 'error',
  SUCCESS = 'success',
  SUBMITTED = 'submitted',
}

export enum LeadTimelineTypeEnum {
  LEAD = 'lead',
  LEAD_STATUS = 'lead_status',
  LEAD_SCHEDULE = 'lead_schedule',
  CHILD = 'child',
  CHILD_STATUS = 'child_status',
  CONTACT = 'contact',
  COMMENT = 'comment',
  FILE = 'file',
  EMAIL = 'email',
  SMS = 'sms',
  CALL = 'call',
  TASK = 'task',
  TASK_STATUS = 'task_status',
  FACEBOOK_MESSAGE = 'facebook_message',
  PROCARE = 'procare',
  PROCARE_ONLINE = 'procare_online',
  KANGAROOTIME = 'kangarootime',
  FORM_SUBMIT = 'form_submit',
  EXTERNAL_APIV2_SUBMIT = 'external_apiv2_submit',
  EXTERNAL_CARECOM_SUBMIT = 'external_carecom_submit',
  EXTERNAL_WINNIE_SUBMIT = 'external_winnie_submit',
  MERGE = 'merge',
  FAMLY = 'famly',
  IF_LEAD_PACKAGE = 'if_lead_package',
}

export enum LeadTimelineSortEnum {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
}

export enum LeadTimelineFilterEnum {
  CONVERSATIONS = 'conversations',
  NOTIFICATIONS = 'notifications',
  INTEGRATIONS = 'integrations',
  STATUSES = 'statuses',
  COMMENTS = 'comments',
  TASKS = 'tasks',
  LEAD_PACKAGES = 'lead_packages',
  OTHER = 'other',
}

export enum BaseLeadStatusEnum {
  NEW = 'New',
  ACTIVE = 'Active',
  PASSIVE = 'Passive',
  WAITLIST = 'Waitlist',
  CUSTOMER = 'Customer',
  ARCHIVED = 'Archived',
  NO_STATUS = '-No Status-',
}

export enum BaseChildStatusEnum {
  ACTIVE = 'Active',
  WAITLIST = 'Waitlist',
  ENROLLED = 'Enrolled',
  ARCHIVED = 'Archived',
  NO_STATUS = '-No Status-',
}

// LMS
export enum LmsLeadRoutesEnum {
  CURRENT = 'current',
  NEW = 'new',
  SCHEDULED = 'scheduled',
  NEEDS_ACTION = 'actions',
  HOT = 'hot',
  INCOMPLETE = 'incomplete',
  WAITLIST = 'waitlist',
  REGISTERED = 'registered',
  ARCHIVED = 'archived',
  DELETED = 'deleted',
  ALL = 'all',
}

export const LmsLeadRoutesTitleMapEnum = {
  [LmsLeadRoutesEnum.CURRENT]: 'Leads',
  [LmsLeadRoutesEnum.NEW]: 'New Leads',
  [LmsLeadRoutesEnum.SCHEDULED]: 'Scheduled Leads',
  [LmsLeadRoutesEnum.NEEDS_ACTION]: 'Actions To Take',
  [LmsLeadRoutesEnum.HOT]: 'Hot Leads',
  [LmsLeadRoutesEnum.INCOMPLETE]: 'Incomplete Leads',
  [LmsLeadRoutesEnum.WAITLIST]: 'Waitlist',
  [LmsLeadRoutesEnum.REGISTERED]: 'Registered Leads',
  [LmsLeadRoutesEnum.ARCHIVED]: 'Archived Leads',
  [LmsLeadRoutesEnum.DELETED]: 'Deleted Leads',
  [LmsLeadRoutesEnum.ALL]: 'All Leads',
}

export const LmsLeadRoutesMenuMapEnum = {
  [LmsLeadRoutesEnum.CURRENT]: 'Leads',
  [LmsLeadRoutesEnum.NEW]: 'New',
  [LmsLeadRoutesEnum.SCHEDULED]: 'Scheduled',
  [LmsLeadRoutesEnum.NEEDS_ACTION]: 'Actions To Take',
  [LmsLeadRoutesEnum.HOT]: 'Hot',
  [LmsLeadRoutesEnum.INCOMPLETE]: 'Incomplete',
  [LmsLeadRoutesEnum.WAITLIST]: 'Waitlist',
  [LmsLeadRoutesEnum.REGISTERED]: 'Registered',
  [LmsLeadRoutesEnum.ARCHIVED]: 'Archived',
  [LmsLeadRoutesEnum.DELETED]: 'Deleted',
  [LmsLeadRoutesEnum.ALL]: 'All Leads',
}

export enum LeadDeletedReasonsEnum {
  OTHER = 'Other',
  SPAM = 'Wrong Number/Spam',
  DUPLICATE_LEAD = 'Duplicate Lead',
}
