import { Actions, Getters, Module, Mutations } from 'vuex-smart-module'
import { auth as AuthModule, AuthEvents } from '@/store/Auth'
import { business as BusinessModule, BusinessEvents } from '@/store/Business'
import { dashboard as DashboardModule } from '@/store/Dashboard'
import { Store } from 'vuex'
import store from '@/store/index'
import { task as TaskModule } from '@/store/Task'

class RootState {
  authLoaded = false
  businessLoaded = false
}

class RootGetters extends Getters<RootState> {
  get authLoaded(): boolean {
    return this.state.authLoaded
  }

  get businessLoaded(): boolean {
    return this.state.businessLoaded
  }
}
class RootMutations extends Mutations<RootState> {
  setAuthLoaded(loaded: boolean) {
    this.state.authLoaded = loaded
  }
  setBusinessLoaded(loaded: boolean) {
    this.state.businessLoaded = loaded
  }
}
class RootActions extends Actions<RootState, RootGetters, RootMutations, RootActions> {
  store!: Store<any>
  $init(store: Store<unknown>): void {
    store.subscribeAction(({ type }) => {
      if (type === `AuthModule/${AuthEvents.Initialized}`) {
        this.mutations.setAuthLoaded(true)
      }
      if (type === `BusinessModule/${BusinessEvents.Initialized}`) {
        this.mutations.setBusinessLoaded(true)
      }
    })
    this.store = store
  }

  async initialized(): Promise<void> {
    await Promise.all([this.dispatch('authInitialized'), this.dispatch('businessInitialized')])
  }
  async authInitialized() {
    if (!store.getters['AuthModule/isAuthenticated'] || this.state.authLoaded) {
      return
    }

    return new Promise((resolve) => {
      const unwatch = store.watch(
        (_, getters) => getters.authLoaded,
        (nv) => {
          if (nv) {
            resolve(true)
            unwatch()
          }
        },
      )
    })
  }
  async businessInitialized() {
    if (!store.getters['BusinessModule/businessSlug'] || this.state.businessLoaded) {
      return
    }

    return new Promise((resolve) => {
      const unwatch = store.watch(
        (_, getters) => getters.businessLoaded,
        (nv) => {
          if (nv) {
            resolve(true)
            unwatch()
          }
        },
      )
    })
  }
}

export default new Module({
  modules: {
    AuthModule,
    BusinessModule,
    DashboardModule,
    TaskModule,
  },
  actions: RootActions,
  state: RootState,
  getters: RootGetters,
  mutations: RootMutations,
})
