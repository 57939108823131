import { defineComponent, h } from 'vue'
import type { RouteRecordRaw } from 'vue-router'
import { MenuCode } from '@/shared'
import { RouteList } from '@/routes/RouteList'
import { FeatureEnum } from '@/enums/FeatureEnum'
import { AbilityEnum } from '@/enums/AbilityEnum'

const TaskTypesView = () => import('@/views/Tasks/TaskTypesView.vue')
const TaskTemplateView = () => import('@/views/Tasks/TaskTemplateView.vue')
const TasksView = () => import('@/views/Tasks/TasksView.vue')
const TaskView = () => import('@/views/Tasks/TaskView.vue')
const DashboardMain = () => import('@/components/Dashboard/DashboardMain.vue')
const SubSideBar = () => import('@/components/Layout/SubSideBar.vue')
const AuthNavBar = () => import('@/components/Layout/AuthenticatedNavBar.vue')

const taskRoutes: RouteRecordRaw[] = [
  {
    path: RouteList.TASKS.TASKS.INDEX.PATH,
    name: RouteList.TASKS.TASKS.INDEX.NAME,
    components: {
      default: DashboardMain,
      NavBar: AuthNavBar,
      SideBar: SubSideBar,
    },
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
    },
    props: {
      SideBar: {
        menuCode: MenuCode.Task,
        title: 'Tasks',
      },
    },
    redirect: {
      name: RouteList.TASKS.TASKS.ALL.NAME,
    },
    children: [
      {
        path: RouteList.TASKS.TASKS.ALL.PATH,
        name: RouteList.TASKS.TASKS.ALL.NAME,
        component: TasksView,
        meta: {
          feature: [FeatureEnum.SETTINGS_TASKS],
          permission: [AbilityEnum.TASKS_VIEW, AbilityEnum.TASKS],
        },
      },
      {
        path: RouteList.TASKS.TASKS.MY_TASKS.PATH,
        name: RouteList.TASKS.TASKS.MY_TASKS.NAME,
        component: TasksView,
        meta: {
          feature: [FeatureEnum.SETTINGS_TASKS],
          permission: [AbilityEnum.TASKS_VIEW, AbilityEnum.TASKS],
        },
      },
      {
        path: RouteList.TASKS.TASKS.ACTIONS_TO_TAKE.PATH,
        name: RouteList.TASKS.TASKS.ACTIONS_TO_TAKE.NAME,
        component: TasksView,
        meta: {
          feature: [FeatureEnum.SETTINGS_TASKS],
          permission: [AbilityEnum.TASKS_VIEW, AbilityEnum.TASKS],
        },
      },
      {
        path: RouteList.TASKS.TASKS.WITHOUT_ASSIGNEE.PATH,
        name: RouteList.TASKS.TASKS.WITHOUT_ASSIGNEE.NAME,
        component: TasksView,
        meta: {
          feature: [FeatureEnum.SETTINGS_TASKS],
          permission: [AbilityEnum.TASKS_VIEW, AbilityEnum.TASKS],
        },
      },
      {
        path: RouteList.TASKS.TASKS.CREATED_BY_ME.PATH,
        name: RouteList.TASKS.TASKS.CREATED_BY_ME.NAME,
        component: TasksView,
        meta: {
          feature: [FeatureEnum.SETTINGS_TASKS],
          permission: [AbilityEnum.TASKS_VIEW, AbilityEnum.TASKS],
        },
      },
      {
        path: RouteList.TASKS.TASKS.ARCHIVE.PATH,
        name: RouteList.TASKS.TASKS.ARCHIVE.NAME,
        component: TasksView,
        meta: {
          feature: [FeatureEnum.SETTINGS_TASKS],
          permission: [AbilityEnum.TASKS_VIEW, AbilityEnum.TASKS],
        },
      },
      {
        path: RouteList.TASKS.TASKS.TASK.PATH,
        name: RouteList.TASKS.TASKS.TASK.NAME,
        component: TaskView,
        meta: {
          feature: [FeatureEnum.SETTINGS_TASKS],
          permission: [AbilityEnum.TASKS_VIEW, AbilityEnum.TASKS],
        },
      },
      {
        path: RouteList.TASKS.TASK_TYPES.INDEX.PATH,
        name: RouteList.TASKS.TASK_TYPES.INDEX.NAME,
        component: TaskTypesView,
        meta: {
          feature: [FeatureEnum.SETTINGS_TASKS],
          permission: [AbilityEnum.SETTING_TASKS_TYPES, AbilityEnum.TASKS],
        },
      },
      {
        path: RouteList.TASKS.TASKS_TEMPLATES.INDEX.PATH,
        name: RouteList.TASKS.TASKS_TEMPLATES.INDEX.NAME,
        redirect: {
          name: RouteList.TASKS.TASKS_TEMPLATES.BUSINESS.NAME,
        },
        component: defineComponent({ render: () => h('div') }),
        meta: {
          feature: [FeatureEnum.SETTINGS_TASKS],
          permission: [AbilityEnum.SETTING_TASKS_TEMPLATES, AbilityEnum.TASKS],
        },
      },
      {
        path: RouteList.TASKS.TASKS_TEMPLATES.BUSINESS.PATH,
        name: RouteList.TASKS.TASKS_TEMPLATES.BUSINESS.NAME,
        component: TaskTemplateView,
        meta: {
          feature: [FeatureEnum.SETTINGS_TASKS],
          permission: [AbilityEnum.SETTING_TASKS_TEMPLATES, AbilityEnum.TASKS],
        },
      },
      {
        path: RouteList.TASKS.TASKS_TEMPLATES.LMS.PATH,
        name: RouteList.TASKS.TASKS_TEMPLATES.LMS.NAME,
        component: TaskTemplateView,
        meta: {
          feature: [FeatureEnum.SETTINGS_TASKS],
          permission: [
            AbilityEnum.LMS_LEADS,
            AbilityEnum.SETTING_TASKS_TEMPLATES,
            AbilityEnum.TASKS,
          ],
        },
      },
      {
        path: RouteList.TASKS.TASKS_TEMPLATES.EMS.PATH,
        name: RouteList.TASKS.TASKS_TEMPLATES.EMS.NAME,
        component: TaskTemplateView,
        meta: {
          feature: [FeatureEnum.SETTINGS_TASKS],
          permission: [
            AbilityEnum.EMS_LEADS,
            AbilityEnum.SETTING_TASKS_TEMPLATES,
            AbilityEnum.TASKS,
          ],
        },
      },
    ],
  },
]

export default <RouteRecordRaw[]>[...taskRoutes]
