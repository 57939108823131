export enum AbilityEnum {
  LMS = 'lms',

  LMS_LEADS = 'lms_leads',

  LMS_LEAD_CREATE = 'lms_leads:create',

  LMS_LEAD_UPDATE = 'lms_leads:update',

  LMS_LEAD_DELETE = 'lms_leads:delete',
  
  LMS_LEAD_MERGER = 'lms_leads:merge',

  LMS_LEAD_VIEW = 'lms_leads:view',

  LMS_LEAD_COMMENT = 'lms_lead_comment:create',

  LMS_LEAD_SEND_EMAIL = 'lms_leads:send_email',

  LMS_LEAD_SEND_SMS = 'lms_leads:send_sms',

  LMS_LEAD_CALL = 'lms_leads:call',

  LMS_LEAD_CHILD = 'lms_lead_child',

  LMS_LEAD_CHILD_CREATE = 'lms_lead_child:create',

  LMS_LEAD_CHILD_UPDATE = 'lms_lead_child:update',

  LMS_LEAD_CHILD_DELETE = 'lms_lead_child:delete',

  LMS_LEAD_CHILD_VIEW = 'lms_lead_child:view',

  LMS_LEAD_CONTACT = 'lms_lead_contact',

  LMS_LEAD_CONTACT_CREATE = 'lms_lead_contact:create',

  LMS_LEAD_CONTACT_UPDATE = 'lms_lead_contact:update',

  LMS_LEAD_CONTACT_DELETE = 'lms_lead_contact:delete',

  LMS_LEAD_CONTACT_VIEW = 'lms_lead_contact:view',

  // todo: remove/replace with lead_task abilities
  LMS_LEAD_SCHEDULE_ACTION = 'lms_lead_schedule_action',

  LMS_LEAD_SCHEDULE_ACTION_CREATE = 'lms_lead_schedule_action:create',

  LMS_LEAD_SCHEDULE_ACTION_DELETE = 'lms_lead_schedule_action:delete',

  LMS_LEAD_SCHEDULE_ACTION_UPDATE = 'lms_lead_schedule_action:update',

  LMS_LEAD_SCHEDULE_ACTION_VIEW = 'lms_lead_schedule_action:view',
  // todo: remove/replace with lead_task abilities

  LMS_LEAD_FILE = 'lms_lead_file',

  LMS_LEAD_FILE_CREATE = 'lms_lead_file:create',

  LMS_LEAD_FILE_UPDATE = 'lms_lead_file:update',

  LMS_LEAD_FILE_DELETE = 'lms_lead_file:delete',

  LMS_LEAD_FILE_VIEW = 'lms_lead_file:view',

  LMS_CENTER = 'lms_center',

  LMS_CENTER_CLASSROOM = 'lms_center_classroom',

  LMS_CENTER_CLASSROOM_VIEW = 'lms_center_classroom:view',

  LMS_CENTER_CLASSROOM_CREATE = 'lms_center_classroom:create',

  LMS_CENTER_CLASSROOM_EDIT = 'lms_center_classroom:edit',

  LMS_CENTER_CLASSROOM_DELETE = 'lms_center_classroom:delete',

  LMS_CENTER_CLASSROOM_COPY = 'lms_center_classroom:copy',

  LMS_STATISTIC = 'lms_statistic',

  LMS_STATISTIC_QUICK_OVERVIEW = 'lms_statistic_quick_overview',

  LMS_STATISTIC_REPORT = 'lms_statistic_report',

  LMS_CONVERSATION = 'lms_conversation',

  LMS_CONVERSATION_CALL = 'lms_conversation_call',

  LMS_CONVERSATION_CALL_RECORDING_ACCESS = 'lms_conversation_call_recording:access',

  LMS_CONVERSATION_CALL_RECORDING_DELETE = 'lms_conversation_call_recording:delete',

  LMS_CONVERSATION_SMS = 'lms_conversation_sms',

  LMS_CONVERSATION_EMAIL = 'lms_conversation_email',

  LMS_CONVERSATION_MESSENGER = 'lms_conversation_messenger',

  LMS_MARKETING_AUTOMATION = 'lms_marketing_automation',

  LMS_MARKETING_AUTOMATION_WORKFLOW = 'lms_marketing_automation_workflow',

  LMS_MARKETING_AUTOMATION_WORKFLOW_CREATE = 'lms_marketing_automation_workflow:create',

  LMS_MARKETING_AUTOMATION_WORKFLOW_UPDATE = 'lms_marketing_automation_workflow:update',

  LMS_MARKETING_AUTOMATION_WORKFLOW_VIEW = 'lms_marketing_automation_workflow:view',

  LMS_MARKETING_AUTOMATION_WORKFLOW_DELETE = 'lms_marketing_automation_workflow:delete',

  LMS_MARKETING_AUTOMATION_TEMPLATE = 'lms_marketing_automation_template',

  LMS_MARKETING_AUTOMATION_SMS_TEMPLATE = 'lms_marketing_automation_sms_template',

  LMS_MARKETING_AUTOMATION_SMS_TEMPLATE_CREATE = 'lms_marketing_automation_sms_template:create',

  LMS_MARKETING_AUTOMATION_SMS_TEMPLATE_UPDATE = 'lms_marketing_automation_sms_template:update',

  LMS_MARKETING_AUTOMATION_SMS_TEMPLATE_DELETE = 'lms_marketing_automation_sms_template:delete',

  LMS_MARKETING_AUTOMATION_EMAIL_TEMPLATE = 'lms_marketing_automation_email_template',

  LMS_MARKETING_AUTOMATION_EMAIL_TEMPLATE_CREATE = 'lms_marketing_automation_email_template:create',

  LMS_MARKETING_AUTOMATION_EMAIL_TEMPLATE_UPDATE = 'lms_marketing_automation_email_template:update',

  LMS_MARKETING_AUTOMATION_EMAIL_TEMPLATE_DELETE = 'lms_marketing_automation_email_template:delete',

  LMS_MARKETING_AUTOMATION_FACEBOOK_MESSAGE_TEMPLATE = 'lms_marketing_automation_facebook_message_template',

  LMS_MARKETING_AUTOMATION_FACEBOOK_MESSAGE_TEMPLATE_CREATE = 'lms_marketing_automation_facebook_message_template:create',

  LMS_MARKETING_AUTOMATION_FACEBOOK_MESSAGE_TEMPLATE_UPDATE = 'lms_marketing_automation_facebook_message_template:update',

  LMS_MARKETING_AUTOMATION_FACEBOOK_MESSAGE_TEMPLATE_DELETE = 'lms_marketing_automation_facebook_message_template:delete',

  LMS_MARKETING_AUTOMATION_CAMPAIGN = 'lms_marketing_automation_campaign',

  LMS_MARKETING_AUTOMATION_CAMPAIGN_VIEW = 'lms_marketing_automation_campaign:view',

  LMS_MARKETING_AUTOMATION_CAMPAIGN_CREATE = 'lms_marketing_automation_campaign:create',

  LMS_MARKETING_AUTOMATION_CAMPAIGN_UPDATE = 'lms_marketing_automation_campaign:update',

  LMS_MARKETING_AUTOMATION_CAMPAIGN_DELETE = 'lms_marketing_automation_campaign:delete',

  LMS_MARKETING_AUTOMATION_CAMPAIGN_SEND_EMAIL = 'lms_marketing_automation_campaign:send_email',

  LMS_MARKETING_AUTOMATION_CAMPAIGN_SEND_SMS = 'lms_marketing_automation_campaign:send_sms',

  LMS_MARKETING_AUTOMATION_FORMS = 'lms_marketing_automation_forms',

  LMS_MARKETING_AUTOMATION_FORMS_VIEW = 'lms_marketing_automation_forms:view',

  LMS_MARKETING_AUTOMATION_FORMS_CREATE = 'lms_marketing_automation_forms:create',

  LMS_MARKETING_AUTOMATION_FORMS_UPDATE = 'lms_marketing_automation_forms:update',

  LMS_MARKETING_AUTOMATION_FORMS_DELETE = 'lms_marketing_automation_forms:delete',

  LMS_INTELLIFORM = 'lms_intelliform',

  LMS_INTELLIFORM_PACKAGE = 'lms_intelliform_packages',

  LMS_INTELLIFORM_PACKAGE_CREATE = 'lms_intelliform_package:create',

  LMS_INTELLIFORM_PACKAGE_UPDATE = 'lms_intelliform_package:update',

  LMS_INTELLIFORM_PACKAGE_VIEW = 'lms_intelliform_package:view',

  LMS_INTELLIFORM_PACKAGE_VIEW_UNFINISHED = 'lms_intelliform_package:view_unfinished',

  LMS_INTELLIFORM_PACKAGE_DELETE = 'lms_intelliform_package:delete',

  LMS_INTELLIFORM_DOCUMENT = 'lms_intelliform_document',

  LMS_INTELLIFORM_DOCUMENT_CREATE = 'lms_intelliform_document:create',

  LMS_INTELLIFORM_DOCUMENT_UPDATE = 'lms_intelliform_document:update',

  LMS_INTELLIFORM_DOCUMENT_VIEW = 'lms_intelliform_document:view',

  LMS_INTELLIFORM_DOCUMENT_DELETE = 'lms_intelliform_document:delete',

  LMS_TOOL = 'lms_tool',

  LMS_TOOL_BULK_UPDATE = 'lms_tool_bulk_update',

  LMS_TOOL_IMPORT = 'lms_tool_import',

  LMS_TOOL_EXPORT = 'lms_tool_export',

  LMS_TOOL_DUPLICATE = 'lms_tool_duplicate',

  EMS = 'ems',

  EMS_LEADS = 'ems_leads',

  EMS_LEAD_CREATE = 'ems_leads:create',

  EMS_LEAD_UPDATE = 'ems_leads:update',

  EMS_LEAD_DELETE = 'ems_leads:delete',

  EMS_LEAD_MERGER = 'ems_leads:merge',

  EMS_LEAD_VIEW = 'ems_leads:view',

  EMS_LEAD_COMMENT = 'ems_lead_comment:create',

  EMS_LEAD_SEND_EMAIL = 'ems_leads:send_email',

  EMS_LEAD_SEND_SMS = 'ems_leads:send_sms',

  EMS_LEAD_CALL = 'ems_leads:call',

  EMS_LEAD_CONTACT = 'ems_lead_contact',

  EMS_LEAD_CONTACT_CREATE = 'ems_lead_contact:create',

  EMS_LEAD_CONTACT_UPDATE = 'ems_lead_contact:update',

  EMS_LEAD_CONTACT_DELETE = 'ems_lead_contact:delete',

  EMS_LEAD_CONTACT_VIEW = 'ems_lead_contact:view',

  EMS_LEAD_SCHEDULE_ACTION = 'ems_lead_schedule_action',

  EMS_LEAD_SCHEDULE_ACTION_CREATE = 'ems_lead_schedule_action:create',

  EMS_LEAD_SCHEDULE_ACTION_DELETE = 'ems_lead_schedule_action:delete',

  EMS_LEAD_SCHEDULE_ACTION_UPDATE = 'ems_lead_schedule_action:update',

  EMS_LEAD_SCHEDULE_ACTION_VIEW = 'ems_lead_schedule_action:view',

  EMS_LEAD_FILE = 'ems_lead_file',

  EMS_LEAD_FILE_CREATE = 'ems_lead_file:create',

  EMS_LEAD_FILE_UPDATE = 'ems_lead_file:update',

  EMS_LEAD_FILE_DELETE = 'ems_lead_file:delete',

  EMS_LEAD_FILE_VIEW = 'ems_lead_file:view',

  EMS_CENTER = 'ems_center',

  EMS_CENTER_CLASSROOM = 'ems_center_classroom',

  EMS_STATISTIC = 'ems_statistic',

  EMS_STATISTIC_QUICK_OVERVIEW = 'ems_statistic_quick_overview',

  EMS_STATISTIC_REPORT = 'ems_statistic_report',

  EMS_CONVERSATION = 'ems_conversation',

  EMS_CONVERSATION_CALL = 'ems_conversation_call',

  EMS_CONVERSATION_CALL_RECORDING_ACCESS = 'ems_conversation_call_recording:access',

  EMS_CONVERSATION_CALL_RECORDING_DELETE = 'ems_conversation_call_recording:delete',

  EMS_CONVERSATION_SMS = 'ems_conversation_sms',

  EMS_CONVERSATION_EMAIL = 'ems_conversation_email',

  EMS_CONVERSATION_MESSENGER = 'ems_conversation_messenger',

  EMS_MARKETING_AUTOMATION = 'ems_marketing_automation',

  EMS_MARKETING_AUTOMATION_WORKFLOW = 'ems_marketing_automation_workflow',

  EMS_MARKETING_AUTOMATION_WORKFLOW_CREATE = 'ems_marketing_automation_workflow:create',

  EMS_MARKETING_AUTOMATION_WORKFLOW_UPDATE = 'ems_marketing_automation_workflow:update',

  EMS_MARKETING_AUTOMATION_WORKFLOW_VIEW = 'ems_marketing_automation_workflow:view',

  EMS_MARKETING_AUTOMATION_WORKFLOW_DELETE = 'ems_marketing_automation_workflow:delete',

  EMS_MARKETING_AUTOMATION_TEMPLATE = 'ems_marketing_automation_template',

  EMS_MARKETING_AUTOMATION_EMAIL_TEMPLATE = 'ems_marketing_automation_email_template',

  EMS_MARKETING_AUTOMATION_EMAIL_TEMPLATE_CREATE = 'ems_marketing_automation_email_template:create',

  EMS_MARKETING_AUTOMATION_EMAIL_TEMPLATE_UPDATE = 'ems_marketing_automation_email_template:update',

  EMS_MARKETING_AUTOMATION_EMAIL_TEMPLATE_DELETE = 'ems_marketing_automation_email_template:delete',

  EMS_MARKETING_AUTOMATION_SMS_TEMPLATE = 'ems_marketing_automation_sms_template',

  EMS_MARKETING_AUTOMATION_SMS_TEMPLATE_CREATE = 'ems_marketing_automation_sms_template:create',

  EMS_MARKETING_AUTOMATION_SMS_TEMPLATE_UPDATE = 'ems_marketing_automation_sms_template:update',

  EMS_MARKETING_AUTOMATION_SMS_TEMPLATE_DELETE = 'ems_marketing_automation_sms_template:delete',

  EMS_MARKETING_AUTOMATION_FACEBOOK_MESSAGE_TEMPLATE = 'ems_marketing_automation_facebook_message_template',

  EMS_MARKETING_AUTOMATION_FACEBOOK_MESSAGE_TEMPLATE_CREATE = 'ems_marketing_automation_facebook_message_template:create',

  EMS_MARKETING_AUTOMATION_FACEBOOK_MESSAGE_TEMPLATE_UPDATE = 'ems_marketing_automation_facebook_message_template:update',

  EMS_MARKETING_AUTOMATION_FACEBOOK_MESSAGE_TEMPLATE_DELETE = 'ems_marketing_automation_facebook_message_template:delete',

  EMS_MARKETING_AUTOMATION_CAMPAIGN = 'ems_marketing_automation_campaign',

  EMS_MARKETING_AUTOMATION_CAMPAIGN_VIEW = 'ems_marketing_automation_campaign:view',

  EMS_MARKETING_AUTOMATION_CAMPAIGN_CREATE = 'ems_marketing_automation_campaign:create',

  EMS_MARKETING_AUTOMATION_CAMPAIGN_UPDATE = 'ems_marketing_automation_campaign:update',

  EMS_MARKETING_AUTOMATION_CAMPAIGN_DELETE = 'ems_marketing_automation_campaign:delete',

  EMS_MARKETING_AUTOMATION_CAMPAIGN_SEND_EMAIL = 'ems_marketing_automation_campaign:send_email',

  EMS_MARKETING_AUTOMATION_CAMPAIGN_SEND_SMS = 'ems_marketing_automation_campaign:send_sms',

  EMS_MARKETING_AUTOMATION_FORMS = 'ems_marketing_automation_forms',

  EMS_MARKETING_AUTOMATION_FORMS_VIEW = 'ems_marketing_automation_forms:view',

  EMS_MARKETING_AUTOMATION_FORMS_CREATE = 'ems_marketing_automation_forms:create',

  EMS_MARKETING_AUTOMATION_FORMS_UPDATE = 'ems_marketing_automation_forms:update',

  EMS_MARKETING_AUTOMATION_FORMS_DELETE = 'ems_marketing_automation_forms:delete',

  EMS_INTELLIFORM = 'ems_intelliform',

  EMS_INTELLIFORM_PACKAGE = 'ems_intelliform_packages',

  EMS_INTELLIFORM_PACKAGE_CREATE = 'ems_intelliform_package:create',

  EMS_INTELLIFORM_PACKAGE_UPDATE = 'ems_intelliform_package:update',

  EMS_INTELLIFORM_PACKAGE_VIEW = 'ems_intelliform_package:view',

  EMS_INTELLIFORM_PACKAGE_VIEW_UNFINISHED = 'ems_intelliform_package:view_unfinished',

  EMS_INTELLIFORM_PACKAGE_DELETE = 'ems_intelliform_package:delete',

  EMS_INTELLIFORM_DOCUMENT = 'ems_intelliform_document',

  EMS_INTELLIFORM_DOCUMENT_CREATE = 'ems_intelliform_document:create',

  EMS_INTELLIFORM_DOCUMENT_UPDATE = 'ems_intelliform_document:update',

  EMS_INTELLIFORM_DOCUMENT_VIEW = 'ems_intelliform_document:view',

  EMS_INTELLIFORM_DOCUMENT_DELETE = 'ems_intelliform_document:delete',

  EMS_TOOL = 'ems_tool',

  EMS_TOOL_BULK_UPDATE = 'ems_tool_bulk_update',

  EMS_TOOL_IMPORT = 'ems_tool_import',

  EMS_TOOL_EXPORT = 'ems_tool_export',

  EMS_TOOL_DUPLICATE = 'ems_tool_duplicate',

  MARKETPLACE = 'marketplace',

  MARKETPLACE_PUBLIC_CONTENT = 'marketplace_public_content',

  MARKETPLACE_SUBSCRIPTIONS = 'marketplace_subscriptions',

  MARKETPLACE_SUBSCRIBE = 'marketplace_subscribe',

  MARKETPLACE_BECOME_PARTNER = 'marketplace_become_partner',

  MARKETPLACE_ACTIONS = 'marketplace_actions',

  MARKETPLACE_ACTIONS_COPY_EMAILS = 'marketplace_actions_copy_emails',

  MARKETPLACE_SETTINGS = 'marketplace_settings',

  MARKETPLACE_SETTINGS_PROFILE = 'marketplace_settings_profile',

  MARKETPLACE_SETTINGS_PROFILE_UPDATE = 'marketplace_settings_profile:update',

  MARKETPLACE_SETTINGS_PROFILE_CONFIGURATION = 'marketplace_settings_profile_configuration',

  MARKETPLACE_SETTINGS_PROFILE_CONFIGURATION_UPDATE = 'marketplace_settings_profile_configuration:update',

  MARKETPLACE_SETTINGS_EMAILS = 'marketplace_settings_emails',

  MARKETPLACE_SETTINGS_EMAILS_VIEW = 'marketplace_settings_emails:view',

  MARKETPLACE_SETTINGS_EMAILS_CREATE = 'marketplace_settings_emails:create',

  MARKETPLACE_SETTINGS_EMAILS_UPDATE = 'marketplace_settings_emails:update',

  MARKETPLACE_SETTINGS_EMAILS_DELETE = 'marketplace_settings_emails:delete',

  MARKETPLACE_SETTINGS_SUBSCRIBERS = 'marketplace_settings_subscribers',

  WEBSITE = 'website',

  SETTING = 'setting',

  SETTING_BUSINESS = 'settings_business',

  SETTING_BUSINESS_BUSINESS = 'setting_business_business',

  SETTING_BUSINESS_UPDATE = 'setting_business_business:update',

  SETTING_BUSINESS_GENERAL_SETTINGS = 'setting_business_business_general_settings',

  SETTING_BUSINESS_GENERAL_SETTINGS_UPDATE = 'setting_business_business_general_settings:update',

  SETTING_BUSINESS_WORKINGS_HOURS = 'setting_business_businessworking_hours',

  SETTING_BUSINESS_WORKINGS_HOURS_UPDATE = 'setting_business_business_working_hours:update',

  SETTING_BUSINESS_CONVERSATIONS_SETTINGS = 'setting_business_business_conversation_settings',

  SETTING_BUSINESS_CONVERSATIONS_SETTINGS_CREATE = 'setting_business_business_conversation_settings:create',

  SETTING_BUSINESS_CONVERSATIONS_SETTINGS_UPDATE = 'setting_business_business_conversation_settings:update',

  SETTING_BUSINESS_CONVERSATIONS_SETTINGS_DELETE = 'setting_business_business_conversation_settings:delete',

  SETTING_BUSINESS_LOCATION = 'setting_business_location',

  SETTING_BUSINESS_SCHEDULERS = 'setting_business_schedulers',

  SETTING_BUSINESS_SCHEDULERS_VIEW = 'setting_business_schedulers:view',

  SETTING_BUSINESS_SCHEDULERS_CREATE = 'setting_business_schedulers:create',

  SETTING_BUSINESS_SCHEDULERS_UPDATE = 'setting_business_schedulers:update',

  SETTING_BUSINESS_SCHEDULERS_DELETE = 'setting_business_schedulers:delete',

  SETTING_LOCATION_SCHEDULERS = 'setting_location_schedulers',

  SETTING_LOCATION_SCHEDULERS_VIEW = 'setting_location_schedulers:view',

  SETTING_LOCATION_SCHEDULERS_CREATE = 'setting_location_schedulers:create',

  SETTING_LOCATION_SCHEDULERS_UPDATE = 'setting_location_schedulers:update',

  SETTING_LOCATION_SCHEDULERS_DELETE = 'setting_location_schedulers:delete',

  SETTING_LOCATION_CREATE = 'setting_business_location:create',

  SETTING_LOCATION_UPDATE = 'setting_business_location:update',

  SETTING_LOCATION_VIEW = 'setting_business_location:view',

  SETTING_LOCATION_DELETE = 'setting_business_location:delete',

  SETTING_LOCATION_EDIT = 'setting_business_location:edit',

  SETTING_LOCATION_GENERAL_SETTINGS = 'setting_business_location_general_settings',

  SETTING_LOCATION_GENERAL_SETTINGS_UPDATE = 'setting_business_location_general_settings:update',

  SETTING_LOCATION_WORKINGS_HOURS = 'setting_business_location_working_hours',

  SETTING_LOCATION_WORKINGS_HOURS_UPDATE = 'setting_business_location_working_hours:update',

  SETTING_LOCATION_CONVERSATIONS_SETTINGS = 'setting_business_location_conversation_settings',

  SETTING_LOCATION_CONVERSATIONS_SETTINGS_CREATE = 'setting_business_location_conversation_settings:create',

  SETTING_LOCATION_CONVERSATIONS_SETTINGS_UPDATE = 'setting_business_location_conversation_settings:update',

  SETTING_LOCATION_CONVERSATIONS_SETTINGS_DELETE = 'setting_business_location_conversation_settings:delete',

  SETTING_LOCATION_CLASSROOMS = 'setting_location_classroom',

  SETTING_LOCATION_CLASSROOMS_VIEW = 'setting_location_classroom:view',

  SETTING_LOCATION_CLASSROOMS_CREATE = 'setting_location_classroom:create',

  SETTING_LOCATION_CLASSROOMS_UPDATE = 'setting_location_classroom:update',

  SETTING_LOCATION_CLASSROOMS_DELETE = 'setting_location_classroom:delete',

  SETTING_LOCATION_CLASSROOMS_COPY = 'setting_location_classroom:copy',

  SETTING_BUSINESS_DATA_FIELD = 'setting_business_data_field',

  SETTING_BUSINESS_DATA_FIELD_CREATE = 'setting_business_data_field:create',

  SETTING_BUSINESS_DATA_FIELD_UPDATE = 'setting_business_data_field:update',

  SETTING_BUSINESS_DATA_FIELD_DELETE = 'setting_business_data_field:delete',

  SETTING_LMS = 'setting_lms',

  SETTING_LMS_STATUS = 'setting_lms_status',

  SETTING_LMS_STATUS_CREATE = 'setting_lms_status:create',

  SETTING_LMS_STATUS_UPDATE = 'setting_lms_status:update',

  SETTING_LMS_STATUS_DELETE = 'setting_lms_status:delete',

  SETTING_LMS_CHILD_STATUS = 'setting_lms_child_status',

  SETTING_LMS_CHILD_STATUS_CREATE = 'setting_lms_child_status:create',

  SETTING_LMS_CHILD_STATUS_UPDATE = 'setting_lms_child_status:update',

  SETTING_LMS_CHILD_STATUS_DELETE = 'setting_lms_child_status:delete',

  SETTING_LMS_DATA_FIELD = 'setting_lms_data_field',

  SETTING_LMS_DATA_FIELD_CREATE = 'setting_lms_data_field:create',

  SETTING_LMS_DATA_FIELD_UPDATE = 'setting_lms_data_field:update',

  SETTING_LMS_DATA_FIELD_DELETE = 'setting_lms_data_field:delete',

  SETTING_EMS = 'setting_ems',

  SETTING_EMS_STATUS = 'setting_ems_status',

  SETTING_EMS_STATUS_CREATE = 'setting_ems_status:create',

  SETTING_EMS_STATUS_UPDATE = 'setting_ems_status:update',

  SETTING_EMS_STATUS_DELETE = 'setting_ems_status:delete',

  SETTING_EMS_DATA_FIELD = 'setting_ems_data_field',

  SETTING_EMS_DATA_FIELD_CREATE = 'setting_ems_data_field:create',

  SETTING_EMS_DATA_FIELD_UPDATE = 'setting_ems_data_field:update',

  SETTING_EMS_DATA_FIELD_DELETE = 'setting_ems_data_field:delete',

  SETTING_COMPANY = 'setting_company',

  SETTING_COMPANY_COMPANY = 'setting_company_company',

  SETTING_COMPANY_COMPANY_CREATE = 'setting_company_company:create',

  SETTING_COMPANY_COMPANY_UPDATE = 'setting_company_company:update',

  SETTING_COMPANY_COMPANY_DELETE = 'setting_company_company:delete',

  SETTING_COMPANY_COMPANY_LOCATIONS_EDIT = 'setting_company_company_locations:edit',

  SETTING_COMPANY_COMPANY_PAYMENT_INSTRUMENT_CREATE = 'setting_company_company_payment_instrument:create',

  SETTING_COMPANY_COMPANY_PAYMENT_INSTRUMENT_DELETE = 'setting_company_company_payment_instrument:delete',

  SETTING_COMPANY_MERCHANT = 'setting_company_merchant',

  SETTING_COMPANY_MERCHANT_PROFILE = 'setting_company_merchant_profile',

  SETTING_COMPANY_MERCHANT_PAYOUT = 'setting_company_merchant_payout',

  SETTING_COMPANY_MERCHANT_TRANSACTION = 'setting_company_merchant_transaction',

  SETTING_COMPANY_MERCHANT_REPORTS = 'setting_company_merchant_reports',

  SETTING_COMPANY_MERCHANT_COMPLIANCE_FORM_PREVIEW = 'setting_company_merchant_compliance_form_preview',

  SETTING_COMPANY_MERCHANT_COMPLIANCE_FORM_ATTEST = 'setting_company_merchant_compliance_form_attest',

  SETTING_BRANDING = 'setting_branding',

  SETTING_BRANDING_EMAIL = 'setting_branding_email',

  SETTING_BRANDING_EMAIL_THEME = 'setting_branding_email_theme',

  SETTING_BRANDING_EMAIL_THEME_CREATE = 'setting_branding_email_theme:create',

  SETTING_BRANDING_EMAIL_THEME_UPDATE = 'setting_branding_email_theme:update',

  SETTING_BRANDING_EMAIL_THEME_DELETE = 'setting_branding_email_theme:delete',

  SETTING_BRANDING_EMAIL_DESIGN = 'setting_branding_email_design',

  SETTING_BRANDING_EMAIL_DESIGN_CREATE = 'setting_branding_email_design:create',

  SETTING_BRANDING_EMAIL_DESIGN_UPDATE = 'setting_branding_email_design:update',

  SETTING_BRANDING_EMAIL_DESIGN_DELETE = 'setting_branding_email_design:delete',

  SETTING_BRANDING_PHONE_NUMBERS = 'setting_branding_phone_numbers',

  SETTING_BRANDING_PHONE_NUMBERS_PHONE_NUMBERS = 'setting_branding_phone_numbers_phone_numbers',

  SETTING_BRANDING_PHONE_NUMBERS_PHONE_NUMBERS_BUY = 'setting_branding_phone_numbers_phone_numbers:buy',

  SETTING_BRANDING_PHONE_NUMBERS_PHONE_NUMBERS_RELEASE = 'setting_branding_phone_numbers_phone_numbers:release',

  SETTING_BRANDING_PHONE_NUMBERS_COMPLIANCE = 'setting_branding_phone_numbers_compliance',

  SETTING_BRANDING_PHONE_NUMBERS_COMPLIANCE_APPLY = 'setting_branding_phone_numbers_compliance:apply',

  SETTING_BRANDING_DOMAINS = 'setting_branding_domain',

  SETTING_BRANDING_DOMAINS_VIEW = 'setting_branding_domain:view',

  SETTING_BRANDING_DOMAINS_CREATE = 'setting_branding_domain:create',

  SETTING_BRANDING_DOMAINS_UPDATE = 'setting_branding_domain:update',

  SETTING_BRANDING_DOMAINS_DELETE = 'setting_branding_domain:delete',

  SETTING_ACCESS = 'setting_access',

  SETTING_ACCESS_USER = 'setting_access_user',

  SETTING_ACCESS_USER_ACTIVE = 'setting_access_user_active',

  SETTING_ACCESS_USER_ACTIVE_CREATE = 'setting_access_user_active:create',

  SETTING_ACCESS_USER_ACTIVE_UPDATE = 'setting_access_user_active:update',

  SETTING_ACCESS_USER_ACTIVE_VIEW = 'setting_access_user_active:view',

  SETTING_ACCESS_USER_ACTIVE_DELETE = 'setting_access_user_active:delete',

  SETTING_ACCESS_USER_INVITATION = 'setting_access_user_invitation',

  SETTING_ACCESS_ROLE = 'setting_access_role',

  SETTING_ACCESS_ROLE_CREATE = 'setting_access_role:create',

  SETTING_ACCESS_ROLE_UPDATE = 'setting_access_role:update',

  SETTING_ACCESS_ROLE_DELETE = 'setting_access_role:delete',

  SETTING_ACCESS_ROLE_MANAGE = 'setting_access_role:manage',

  USERS = 'users',

  USERS_CREATE = 'users:create',

  USERS_UPDATE = 'users:update',

  USERS_VIEW = 'users:view',

  USERS_DELETE = 'users:delete',

  BUSINESS_ACCESS = 'business:access',

  LOCATION_ACCESS = 'location:access',

  LOCATION_GROUP_ACCESS = 'location_group:access',

  COMPANY_ACCESS = 'company:access',

  SETTING_LOCATION_ATTENDANCE_VIEW = 'setting_business_location_attendance:view',

  SETTING_LOCATION_ATTENDANCE_SCHEDULE_UPDATE = 'setting_business_location_attendance_schedule:update',

  SETTING_LOCATION_ATTENDANCE_PROGRAM_CREATE = 'setting_business_location_attendance_program:create',

  SETTING_LOCATION_ATTENDANCE_PROGRAM_UPDATE = 'setting_business_location_attendance_program:update',

  SETTING_LOCATION_ATTENDANCE_PROGRAM_DELETE = 'setting_business_location_attendance_program:delete',

  TASKS = 'tasks',

  TASKS_VIEW = 'tasks:view',

  TASKS_CREATE = 'tasks:create',

  TASKS_UPDATE = 'tasks:update',

  SETTING_TASKS_TYPES = 'task_types',

  SETTING_TASKS_TYPES_VIEW = 'task_types:view',

  SETTING_TASKS_TYPES_CREATE = 'task_types:create',

  SETTING_TASKS_TYPES_UPDATE = 'task_types:update',

  SETTING_TASKS_TYPES_DELETE = 'task_types:delete',

  SETTING_TASKS_TEMPLATES = 'task_templates',

  SETTING_TASKS_TEMPLATES_VIEW = 'task_templates:view',

  SETTING_TASKS_TEMPLATES_CREATE = 'task_templates:create',

  SETTING_TASKS_TEMPlATES_UPDATE = 'task_templates:update',

  SETTING_TASKS_TEMPlATES_DELETE = 'task_templates:delete',
}
