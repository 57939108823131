export default {
  UI: {
    INDEX: {
      PATH: '/ui',
      NAME: 'ui',
    },
    ICONS: {
      PATH: '/ui/icons',
      NAME: 'ui.icons',
    },
  },
}
