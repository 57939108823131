import store from '@/store'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import duration from 'dayjs/plugin/duration'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { Locale } from '@/shared'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)
dayjs.extend(duration)

// TODO apply localization to dates, because US format does not apply to every business
// Also separate between long/short dates and time
const SHORT_DATE = 'M/D/YY' // in US format
// const LONG_DATE = 'M/D/YYYY' // in US format
const DATE_TIME = 'h:mm A' // in US format

export function getBusinessTimezone(): string {
  return store.getters['DashboardModule/timezone']
}

export function getLocale(): string {
  return store.getters['DashboardModule/countryLocale']?.locale.replace('_', '-') ?? Locale.US
}

function isMatch(datetime: string | number | Date): boolean {
  return dayjs(datetime, 'YYYY-MM-DD', true).isValid()
}

export function toLocalDateTimeFormat(datetime: string | number | Date, timezone?: string): string {
  return isMatch(datetime)
    ? dayjs(datetime).format(`${SHORT_DATE}, ${DATE_TIME}`)
    : dayjs(datetime)
        .tz(timezone ?? getBusinessTimezone())
        .format(`${SHORT_DATE}, ${DATE_TIME}`)
}

export function toLocalDateFormat(datetime: string | number | Date): string {
  return isMatch(datetime)
    ? dayjs(datetime).format(`${SHORT_DATE}`)
    : dayjs(datetime).tz(getBusinessTimezone()).format(`${SHORT_DATE}`)
}

export function toLocalLongDateFormat(datetime: string | number | Date): string {
  return isMatch(datetime)
    ? dayjs(datetime).format('MMMM D, YYYY')
    : dayjs(datetime).tz(getBusinessTimezone()).format('MMMM D, YYYY')
}

export function toLocalTimeFormat(datetime: string | number | Date, timezone?: string): string {
  return isMatch(datetime)
    ? dayjs(datetime).format(`${DATE_TIME}`)
    : dayjs(datetime)
        .tz(timezone ?? getBusinessTimezone())
        .format(`${DATE_TIME}`)
}

export function toLocalTime(time: string | undefined): string {
  if (time === undefined) {
    return ''
  }

  const [hours, minutes] = time.split(':')
  const date = new Date()
  date.setUTCHours(Number(hours))
  date.setUTCMinutes(Number(minutes))
  date.setUTCSeconds(0)
  date.setUTCMilliseconds(0)

  return toLocalTimeFormat(date, 'UTC')
}
export function validDateRange(dateRange?: [Date, Date]): [Date, Date] {
  const today = new Date()

  return dateRange ?? [new Date(1900, 0, 1), new Date(today.getFullYear() + 1, 0, 1)]
}

export function dateRangeToOneYearPastFromToday(): [Date, Date] {
  const today = new Date()
  const dateRange = <[Date, Date]>[new Date(1900, 0, 1), new Date(today.getFullYear() + 1, 0, 1)]
  return validDateRange(dateRange)
}

export function dateRangeToToday(): [Date, Date] {
  const today = new Date()
  const dateRange = <[Date, Date]>[new Date(1900, 0, 1), new Date(today)]
  return validDateRange(dateRange)
}

export function toCalendarDateTimeFormat(datetime: string | number | Date | undefined): string {
  if (datetime === undefined) {
    return ''
  }

  return isMatch(datetime)
    ? dayjs(datetime).format('YYYY-MM-DD HH:mm:ss')
    : dayjs(datetime).tz(getBusinessTimezone()).format('YYYY-MM-DD HH:mm:ss')
}

export function formatTimeToUTC(
  datetime: string | number | Date,
  timezone = getBusinessTimezone(),
) {
  return dayjs.tz(datetime, timezone).utc().toISOString()
}

export function formatDateToTimezone(datetime: string, timezone?: string) {
  return dayjs(datetime)
    .tz(timezone ?? getBusinessTimezone())
    .format(`${SHORT_DATE}, ${DATE_TIME}`)
}

export function formatDateToTimezoneCalendar(datetime: string, timezone: string) {
  return dayjs(datetime).tz(timezone).format('YYYY-MM-DD HH:mm:ss')
}

export function unixForPastDate(days = 0, startOfDay = true, timezone?: string) {
  return dayjs()
    .tz(timezone ?? getBusinessTimezone())
    .subtract(days, 'day')
    [startOfDay ? 'startOf' : 'endOf']('day')
    .unix()
}

export function toLocalDateTimeFormatFromUnix(datetime: number): string {
  return dayjs.unix(datetime).format(`${SHORT_DATE}, ${DATE_TIME}`)
}

export function fromUnixToUTC(datetime: number): string {
  return dayjs.unix(datetime).toISOString().split('T')[0]
}

export function toDateTimeFormat(datetime: string | number | Date): string {
  return dayjs(datetime).format(`${SHORT_DATE}, ${DATE_TIME}`)
}
export function toDateFormat(datetime: string | number | Date): string {
  return dayjs(datetime).format(`${SHORT_DATE}`)
}

// Functions from useDate
export const enum ServerDateFormat {
  Date = 'date',
  DateTime = 'datetime',
  DateTimeZ = 'datetimez',
  Time = 'time',
  TimeZ = 'timez',
}

// server accepts this format, so send it like this
export function getServerDateFormat(type?: ServerDateFormat) {
  switch (type) {
    case ServerDateFormat.Date:
      return 'YYYY-MM-DD'
    case ServerDateFormat.DateTime:
      return 'YYYY-MM-DD HH:mm:ss'
    case ServerDateFormat.DateTimeZ:
      return 'YYYY-MM-DD HH:mm:ss Z'
    case ServerDateFormat.Time:
      return 'HH:mm:ss'
    case ServerDateFormat.TimeZ:
      return 'HH:mm:ss Z'
    default:
      return 'YYYY-MM-DD HH:mm:ss'
  }
}

export function convertUtcToTimezone(date: string, timezone = getBusinessTimezone()) {
  return dayjs.utc(date).tz(timezone).format(getServerDateFormat())
}

export function convertTimezoneToUtc(date: string, timezone = getBusinessTimezone()) {
  return dayjs.tz(date, timezone).utc().format(getServerDateFormat())
}

export function dateFormat(short = true) {
  if (short) return 'M/D/YY'
  return 'M/D/YYYY'
}

export function timeFormat(seconds = false) {
  if (seconds) {
    return 'h:mm:ss A'
  }
  return 'h:mm A'
}

export function dateTimeFormat() {
  return dateFormat() + ' ' + timeFormat()
}

// TODO Review:
// - All server dates above and figure out how to reduce

// Dates can be separated by their use:
// - Dates displayed for users on the website, which should be localized
// - Dates sent to the server (various formats)
// - Dates provided to various plugins and packages

// USER DATES START

// New date formats using internationalization, so after providing a locale we have each different country's dates in their local format
// The dates and times can be formatted in 4 different types. Check DateTimeFormat enum

export const enum DateTimeFormat {
  Full = 'full',
  Long = 'long',
  Medium = 'medium',
  Short = 'short',
}

// Generic dates localized for each country by providing a locale
export function genericDate(
  datetime: string,
  dateStyle = DateTimeFormat.Short,
  locale = getLocale(),
) {
  const date = new Date(datetime)

  return new Intl.DateTimeFormat(locale, {
    dateStyle: dateStyle,
  }).format(date)
}

export function genericDateTime(
  datetime: string,
  dateStyle = DateTimeFormat.Short,
  timeStyle = DateTimeFormat.Short,
  timezone = getBusinessTimezone(),
  locale = getLocale(),
) {
  const date = new Date(datetime)

  return new Intl.DateTimeFormat(locale, {
    dateStyle: dateStyle,
    timeStyle: timeStyle,
    timeZone: timezone ? timezone : undefined,
  }).format(date)
}

// Date function names are comprised of:
// - Whether the date is converted to timezone (dates are not localized, only date times)
// - Formatting type of the date (full, long, etc.)
// - Is it the date or dateTime

// Generate localized dates only, no time and timezone used
export function localFullDate(date: string): string {
  return genericDate(date, DateTimeFormat.Full)
}

export function localLongDate(date: string): string {
  return genericDate(date, DateTimeFormat.Long)
}

export function localMediumDate(date: string): string {
  return genericDate(date, DateTimeFormat.Medium)
}

export function localShortDate(date: string): string {
  return genericDate(date)
}

// Generate localized dates with time using timezone
export function localFullDateTime(date: string, timezone?: string): string {
  return genericDateTime(date, DateTimeFormat.Full, DateTimeFormat.Full, timezone)
}

export function localLongDateTime(date: string, timezone?: string): string {
  return genericDateTime(date, DateTimeFormat.Long, DateTimeFormat.Long, timezone)
}

export function localMediumDateTime(date: string, timezone?: string): string {
  return genericDateTime(date, DateTimeFormat.Medium, DateTimeFormat.Medium, timezone)
}

export function localShortDateTime(date: string, timezone?: string): string {
  return genericDateTime(date, DateTimeFormat.Short, DateTimeFormat.Short, timezone)
}

// USER DATES END
