export enum FeatureEnum {
  LMS = 'lms',

  EMS = 'ems',

  LMS_LEADS = 'lms_leads',

  LMS_CENTER = 'lms_center',

  LMS_CENTER_CLASSROOMS = 'lms_center_classrooms',

  LMS_STATISTICS = 'lms_statistics',

  LMS_STATISTICS_OVERVIEW = 'lms_statistics_overview',

  LMS_STATISTICS_REPORT = 'lms_statistics_report',

  LMS_CONVERSATIONS = 'lms_conversations',

  LMS_CONVERSATIONS_CALL = 'lms_conversations_call',

  LMS_CONVERSATIONS_SMS = 'lms_conversations_sms',

  LMS_CONVERSATIONS_EMAIL = 'lms_conversations_email',

  LMS_CONVERSATIONS_MESSENGER = 'lms_conversations_messenger',

  LMS_MARKETING_AUTOMATION = 'lms_marketing_automation',

  LMS_MARKETING_AUTOMATION_WORKFLOW = 'lms_marketing_automation_workflow',

  LMS_MARKETING_AUTOMATION_TEMPLATE = 'lms_marketing_automation_template',

  LMS_MARKETING_AUTOMATION_SMS_TEMPLATE = 'lms_marketing_automation_sms_template',

  LMS_MARKETING_AUTOMATION_EMAIL_TEMPLATE = 'lms_marketing_automation_email_template',

  LMS_MARKETING_AUTOMATION_CAMPAIGN = 'lms_marketing_automation_campaign',

  LMS_MARKETING_AUTOMATION_CAMPAIGN_EMAIL = 'lms_marketing_automation_campaign_email',

  LMS_MARKETING_AUTOMATION_CAMPAIGN_SMS = 'lms_marketing_automation_campaign_sms',

  LMS_MARKETING_AUTOMATION_FORMS = 'lms_marketing_automation_forms',

  INTELLIFORMS = 'intelliforms',

  LMS_INTELLIFORMS = 'lms_intelliforms',

  LMS_INTELLIFORMS_PACKAGE = 'lms_intelliforms_packages',

  LMS_INTELLIFORMS_DOCUMENT = 'lms_intelliforms_document',

  LMS_TOOLS = 'lms_tools',

  LMS_TOOLS_BULK_UPDATE = 'lms_tools_bulk_update',

  LMS_TOOLS_IMPORT = 'lms_tools_import',

  LMS_TOOLS_EXPORT = 'lms_tools_export',

  LMS_TOOLS_DUPLICATE = 'lms_tools_duplicate',

  EMS_CANDIDATES = 'ems_candidates',

  EMS_CENTER = 'ems_center',

  EMS_CENTER_CLASSROOM = 'ems_center_classroom',

  EMS_STATISTICS = 'ems_statistics',

  EMS_STATISTICS_OVERVIEW = 'ems_statistics_overview',

  EMS_STATISTICS_REPORT = 'ems_statistics_report',

  EMS_CONVERSATIONS = 'ems_conversations',

  EMS_CONVERSATIONS_CALL = 'ems_conversations_call',

  EMS_CONVERSATIONS_SMS = 'ems_conversations_sms',

  EMS_CONVERSATIONS_EMAIL = 'ems_conversations_email',

  EMS_CONVERSATIONS_MESSENGER = 'ems_conversations_messenger',

  EMS_MARKETING_AUTOMATION = 'ems_marketing_automation',

  EMS_MARKETING_AUTOMATION_WORKFLOW = 'ems_marketing_automation_workflow',

  EMS_MARKETING_AUTOMATION_TEMPLATE = 'ems_marketing_automation_template',

  EMS_MARKETING_AUTOMATION_SMS_TEMPLATE = 'ems_marketing_automation_sms_template',

  EMS_MARKETING_AUTOMATION_EMAIL_TEMPLATE = 'ems_marketing_automation_email_template',

  EMS_MARKETING_AUTOMATION_CAMPAIGN = 'ems_marketing_automation_campaign',

  EMS_MARKETING_AUTOMATION_CAMPAIGN_EMAIL = 'ems_marketing_automation_campaign_email',

  EMS_MARKETING_AUTOMATION_CAMPAIGN_SMS = 'ems_marketing_automation_campaign_sms',

  EMS_MARKETING_AUTOMATION_FORMS = 'ems_marketing_automation_forms',

  EMS_INTELLIFORMS = 'ems_intelliforms',

  EMS_INTELLIFORMS_PACKAGE = 'ems_intelliforms_packages',

  EMS_INTELLIFORMS_DOCUMENT = 'ems_intelliforms_document',

  EMS_TOOLS = 'ems_tools',

  EMS_TOOLS_BULK_UPDATE = 'ems_tools_bulk_update',

  EMS_TOOLS_IMPORT = 'ems_tools_import',

  EMS_TOOLS_EXPORT = 'ems_tools_export',

  EMS_TOOLS_DUPLICATE = 'ems_tools_duplicate',

  AI_ACCESS = 'ai_access',

  MARKETPLACE = 'marketplace',

  WEBSITE = 'website',

  SETTINGS = 'settings',

  SETTINGS_BUSINESS = 'settings_business',

  SETTINGS_BUSINESS_BUSINESS = 'settings_business_business',

  SETTINGS_BUSINESS_LOCATION = 'settings_business_location',

  SETTINGS_BUSINESS_DATA_FIELD = 'settings_business_data_field',

  SETTINGS_BUSINESS_INTEGRATION = 'settings_business_integration',

  SETTINGS_LMS = 'settings_lms',

  SETTINGS_LMS_STATUS = 'settings_lms_status',

  SETTINGS_LMS_CHILD_STATUS = 'settings_lms_child_status',

  SETTINGS_LMS_DATA_FIELD = 'settings_lms_data_field',

  SETTINGS_EMS = 'settings_ems',

  SETTINGS_EMS_STATUS = 'settings_ems_status',

  SETTINGS_EMS_DATA_FIELD = 'settings_ems_data_field',

  SETTINGS_COMPANY = 'settings_company',

  SETTINGS_COMPANY_COMPANY = 'settings_company_company',

  SETTINGS_COMPANY_MERCHANT = 'settings_company_merchant',

  SETTINGS_BRANDING = 'settings_branding',

  SETTINGS_BRANDING_EMAILS = 'settings_branding_emails',

  SETTINGS_BRANDING_PHONE_NUMBER = 'settings_branding_phone_number',

  SETTINGS_BRANDING_DOMAIN = 'settings_branding_domains',

  SETTINGS_ACCESS = 'settings_access',

  SETTINGS_ACCESS_USERS = 'settings_access_users',

  SETTINGS_ACCESS_ROLES = 'settings_access_roles',

  SETTINGS_TASKS = 'settings_tasks',

  SETTINGS_FACEBOOK = 'settings_facebook',

  USERS = 'users',

  LEARNING = 'learning',

  LEARNING_ACADEMY = 'learning_academy',

  LEARNING_HELP_CENTER = 'learning_help_center',
}
