import type { RouteRecordRaw } from 'vue-router'
import { MenuCode } from '@/shared'
import { RouteList } from '@/routes/RouteList'

const AuthNavBar = () => import('@/components/Layout/AuthenticatedNavBar.vue')
const SubSideBar = () => import('@/components/Layout/SubSideBar.vue')
const DashboardMain = () => import('@/components/Dashboard/DashboardMain.vue')
const EditBusinessUserProfile = () =>
  import('@/components/UserSettings/EditBusinessUserProfile.vue')
const EditBusinessUserNotifications = () =>
  import('@/components/UserSettings/EditBusinessUserNotifications.vue')
const EditUserContacts = () => import('@/components/UserSettings/EditUserContacts.vue')
const EditUserPassword = () => import('@/components/UserSettings/EditUserPassword.vue')
const EditUserPreferences = () => import('@/components/UserSettings/EditUserPreferences.vue')
const UserIntegrations = {
  Index: () => import('@/views/Integrations/UserIntegrationsView.vue'),
  Setup: () => import('@/views/Integrations/UserIntegrationSetup/UserIntegrationSetupView.vue'),
  Google: () =>
    import('@/views/Integrations/UserIntegrationSetup/UserIntegrationSetupGoogleView.vue'),
  Microsoft: () =>
    import('@/views/Integrations/UserIntegrationSetup/UserIntegrationSetupMicrosoftView.vue'),
  Zoom: () => import('@/views/Integrations/UserIntegrationSetup/UserIntegrationSetupZoomView.vue'),
  Facebook: () =>
    import('@/views/Integrations/UserIntegrationSetup/UserIntegrationSetupFacebookView.vue'),
}

const userRoutes: RouteRecordRaw[] = [
  {
    path: RouteList.USERS.SETTINGS.INDEX.PATH,
    name: RouteList.USERS.SETTINGS.INDEX.NAME,
    components: {
      default: DashboardMain,
      NavBar: AuthNavBar,
      SideBar: SubSideBar,
    },
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
    },
    props: {
      SideBar: {
        menuCode: MenuCode.UserSettings,
        title: 'User Settings',
      },
    },
    redirect: {
      name: RouteList.USERS.SETTINGS.CONTACTS.NAME,
    },
    children: [
      {
        path: RouteList.USERS.SETTINGS.CONTACTS.PATH,
        name: RouteList.USERS.SETTINGS.CONTACTS.NAME,
        component: EditUserContacts,
      },
      {
        path: RouteList.USERS.SETTINGS.PASSWORD.PATH,
        name: RouteList.USERS.SETTINGS.PASSWORD.NAME,
        component: EditUserPassword,
      },
      {
        path: RouteList.USERS.SETTINGS.PREFERENCES.PATH,
        name: RouteList.USERS.SETTINGS.PREFERENCES.NAME,
        component: EditUserPreferences,
      },
      {
        path: RouteList.USERS.SETTINGS.INTEGRATIONS.INDEX.PATH,
        name: RouteList.USERS.SETTINGS.INTEGRATIONS.INDEX.NAME,
        component: UserIntegrations.Index,
      },
      {
        path: RouteList.USERS.SETTINGS.INTEGRATIONS.SETUP.PATH,
        name: RouteList.USERS.SETTINGS.INTEGRATIONS.SETUP.NAME,
        component: UserIntegrations.Setup,
      },
      {
        path: RouteList.USERS.SETTINGS.INTEGRATIONS.GOOGLE.PATH,
        name: RouteList.USERS.SETTINGS.INTEGRATIONS.GOOGLE.NAME,
        component: UserIntegrations.Google,
      },
      {
        path: RouteList.USERS.SETTINGS.INTEGRATIONS.MICROSOFT.PATH,
        name: RouteList.USERS.SETTINGS.INTEGRATIONS.MICROSOFT.NAME,
        component: UserIntegrations.Microsoft,
      },
      {
        path: RouteList.USERS.SETTINGS.INTEGRATIONS.ZOOM.PATH,
        name: RouteList.USERS.SETTINGS.INTEGRATIONS.ZOOM.NAME,
        component: UserIntegrations.Zoom,
      },
      {
        path: RouteList.USERS.SETTINGS.INTEGRATIONS.FACEBOOK.PATH,
        name: RouteList.USERS.SETTINGS.INTEGRATIONS.FACEBOOK.NAME,
        component: UserIntegrations.Facebook,
      },
    ],
  },
]

const businessUserRoutes: RouteRecordRaw[] = [
  {
    path: RouteList.USERS.PROFILE.INDEX.PATH,
    name: RouteList.USERS.PROFILE.INDEX.NAME,
    components: {
      default: DashboardMain,
      NavBar: AuthNavBar,
      SideBar: SubSideBar,
    },
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
    },
    props: {
      SideBar: {
        menuCode: MenuCode.UserSettings,
        title: 'User Settings',
      },
    },
    redirect: {
      name: RouteList.USERS.PROFILE.EDIT.NAME,
    },
    children: [
      {
        path: RouteList.USERS.PROFILE.EDIT.PATH,
        name: RouteList.USERS.PROFILE.EDIT.NAME,
        component: EditBusinessUserProfile,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: RouteList.USERS.PROFILE.NOTIFICATIONS.PATH,
        name: RouteList.USERS.PROFILE.NOTIFICATIONS.NAME,
        component: EditBusinessUserNotifications,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
]

export default <RouteRecordRaw[]>[...businessUserRoutes, ...userRoutes]
