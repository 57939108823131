import authRoutesList from '@/routes/RoutesLists/authRoutesList'
import lmsRoutesList from '@/routes/RoutesLists/lmsRoutesList'
import emsRoutesList from '@/routes/RoutesLists/emsRoutesList'
import marketplaceRoutesList from '@/routes/RoutesLists/marketplaceRoutesList'
import websiteRoutesList from '@/routes/RoutesLists/websiteRoutesList'
import settingsRoutesList from '@/routes/RoutesLists/settingsRoutesList'
import usersRoutesList from '@/routes/RoutesLists/usersRoutesList'
import miscRoutesList from '@/routes/RoutesLists/miscRoutesList'
import backOfficeRoutesList from '@/routes/RoutesLists/backOfficeRoutesList'
import publicRoutesList from '@/routes//RoutesLists/publicRoutesList'
import taskRoutesList from '@/routes/RoutesLists/taskRoutesList'
import learningRoutesList from '@/routes/RoutesLists/learningRoutesList'
import uiRoutesList from '@/routes/RoutesLists/uiRoutesList'

export const RouteList = {
  INDEX: {
    PATH: '/',
    NAME: 'index',
  },
  ...authRoutesList,
  ...lmsRoutesList,
  ...emsRoutesList,
  ...marketplaceRoutesList,
  ...websiteRoutesList,
  ...settingsRoutesList,
  ...usersRoutesList,
  ...miscRoutesList,
  ...backOfficeRoutesList,
  ...taskRoutesList,
  ...learningRoutesList,
  ...publicRoutesList,
  ...uiRoutesList,
}
