<template>
  <ATooltip v-if="currentPhoto" :destroy-tooltip-on-hide="true">
    <template v-if="tooltipText.length" #title>
      {{ tooltipText }}
    </template>
    <img :src="currentPhoto" alt="avatar" class="user-image" />
  </ATooltip>

  <BaseLeadStatusBadge
    v-else-if="profile"
    :color="profile.color"
    :label="userName"
    :square="false"
    :border="true"
    :size="size"
    :tooltip-text="tooltipText"
    :deleted="isUserDeleted"
  />
</template>

<script lang="ts" setup>
import { computed, PropType } from 'vue'
import { toInitials } from '@/helpers/string.helper'
import { BadgeSize, LeadStatusColor, MissingNamePlaceholder } from '@/shared'
import { useI18n } from 'vue-i18n'
import { asyncComponentWrapper } from '@/composables/useReloadModal'

const ATooltip = asyncComponentWrapper(() => import('ant-design-vue/es/tooltip'))

const props = defineProps({
  profile: {
    type: [Object, null] as PropType<{
      first_name?: string
      last_name?: string
      color?: LeadStatusColor
      deleted_at?: string | null
      photo?: { url: string | null } | null
    } | null>,
    required: true,
  },
  size: {
    type: String as PropType<BadgeSize>,
    default: 'md',
    required: false,
  },
})

const { t } = useI18n()

const currentPhoto = computed((): string | null => {
  if (props.profile?.photo) {
    return props.profile.photo.url
  }

  return null
})

const userName = computed(() =>
  toInitials(props.profile?.first_name ?? '', props.profile?.last_name ?? ''),
)
const isUserDeleted = computed(() => Boolean(props.profile?.deleted_at))
const tooltipText = computed(() => {
  if (!props.profile?.first_name && !props.profile?.last_name) {
    return t(MissingNamePlaceholder)
  }

  return `${props.profile?.first_name ?? ''} ${props.profile?.last_name ?? ''}`
})
</script>

<style lang="scss" scoped>
.user-image {
  width: 35px;
  min-width: 35px;
  height: 35px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  object-fit: cover;

  > span {
    font-size: 14px;
    font-weight: bold;
  }
}
</style>
