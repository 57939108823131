import { defineComponent, h } from 'vue'
import type { RouteRecordRaw } from 'vue-router'
import { MenuCode } from '@/shared'
import { FeatureEnum } from '@/enums/FeatureEnum'
import { AbilityEnum } from '@/enums/AbilityEnum'
import { RouteList } from '@/routes/RouteList'

const NavBar = () => import('@/components/Layout/AuthenticatedNavBar.vue')
const SubSideBar = () => import('@/components/Layout/SubSideBar.vue')
const DashboardMain = () => import('@/components/Dashboard/DashboardMain.vue')

const LeadList = () => import('@/components/Lead/LeadList.vue')
const LeadShow = () => import('@/components/Lead/LeadShow.vue')
const LmsClassroomView = () => import('@/views/Lms/LmsClassroomView.vue')

const EmailList = () => import('@/components/Conversation/EmailList.vue')
const SmsList = () => import('@/components/Conversation/SmsList.vue')
const CallList = () => import('@/components/Conversation/CallList.vue')
const MessengerList = () => import('@/components/Conversation/MessengerList.vue')

const StatisticsOverview = () => import('@/views/Statistics/StatisticsOverview.vue')
const StatisticsReports = () => import('@/views/Statistics/StatisticsReports.vue')
const StatisticsReportsLeadList = () => import('@/views/Statistics/StatisticsReportsSingle.vue')
const StatisticsEmbeddedReport = () => import('@/views/Statistics/StatisticsEmbeddedReport.vue')

const CampaignList = () => import('@/components/Marketing/Campaign/CampaignList.vue')
const CampaignForm = () => import('@/components/Marketing/Campaign/CampaignForm.vue')
const CampaignShow = () => import('@/components/Marketing/Campaign/CampaignShow.vue')

const TemplatesList = {
  Email: () => import('@/components/Marketing/Template/Email/EmailTemplateList.vue'),
  Sms: () => import('@/components/Marketing/Template/Sms/SmsTemplateList.vue'),
  Task: () => import('@/components/Marketing/Template/Task/TaskTemplateList.vue'),
  Facebook: () => import('@/components/Marketing/Template/Facebook/FacebookTemplateList.vue'),
}
const WorkflowList = () => import('@/components/Marketing/WorkflowList.vue')
const WorkflowSingle = () => import('@/components/Marketing/WorkflowSingle.vue')
const CreateWorkflow = () => import('@/components/Marketing/CreateWorkflow.vue')

const FormsList = () => import('@/views/Forms/FormsListView.vue')
const FormProfile = () => import('@/views/Forms/FormProfile.vue')
const FormCreateEdit = () => import('@/views/Forms/FormCreateEdit.vue')

const IntelliFormsPackagesView = () => import('@/views/Lms/IntelliForms/PackageListView.vue')
const IntelliFormsPackageView = () => import('@/views/Lms/IntelliForms/PackageView.vue')
const IntelliFormsPackageFormView = () => import('@/views/Lms/IntelliForms/PackageFormView.vue')

const IntelliFormsDocumentsView = () => import('@/views/Lms/IntelliForms/DocumentListView.vue')
const IntelliFormsDocumentFormView = () => import('@/views/Lms/IntelliForms/DocumentFormView.vue')

const ExportTools = {
  List: () => import('@/views/Lms/Tools/Export/ExportListView.vue'),
  Create: () => import('@/views/Lms/Tools/Export/ExportCreateView.vue'),
}

const DuplicatesTools = {
  List: () => import('@/views/Lms/Tools/Duplicates/DuplicatesView.vue'),
  Custom: () => import('@/views/Lms/Tools/Duplicates/DuplicatesCustomMergeView.vue'),
  Merge: () => import('@/views/Lms/Tools/Duplicates/DuplicatesMergeView.vue'),
}

const DivComponent = defineComponent({ render: () => h('div') })

export default <RouteRecordRaw[]>[
  {
    path: RouteList.LMS.LMS.PATH,
    name: RouteList.LMS.LMS.NAME,
    components: {
      default: DashboardMain,
      SideBar: SubSideBar,
      NavBar,
    },
    props: {
      SideBar: {
        menuCode: MenuCode.Lms,
        title: 'LMS',
      },
    },
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      feature: [FeatureEnum.LMS],
      permission: [AbilityEnum.LMS],
    },
    redirect: {
      name: RouteList.LMS.LEADS.INDEX.NAME,
    },
    children: [
      {
        path: RouteList.LMS.LEADS.INDEX.PATH,
        name: RouteList.LMS.LEADS.INDEX.NAME,
        component: LeadList,
        meta: {
          title: 'Leads',
          feature: [FeatureEnum.LMS_LEADS, FeatureEnum.LMS],
          permission: [AbilityEnum.LMS_LEADS, AbilityEnum.LMS],
        },
      },
      {
        path: RouteList.LMS.LEADS.LEAD.PATH,
        name: RouteList.LMS.LEADS.LEAD.NAME,
        component: LeadShow,
        meta: {
          title: 'Lead',
          feature: [FeatureEnum.LMS_LEADS, FeatureEnum.LMS],
          permission: [AbilityEnum.LMS_LEAD_VIEW, AbilityEnum.LMS_LEADS, AbilityEnum.LMS],
        },
      },
      {
        path: RouteList.LMS.CENTER.INDEX.PATH,
        name: RouteList.LMS.CENTER.INDEX.NAME,
        meta: {
          feature: [FeatureEnum.LMS_CENTER, FeatureEnum.LMS],
          permission: [AbilityEnum.LMS_CENTER, AbilityEnum.LMS],
        },
        redirect: {
          name: RouteList.LMS.CENTER.CLASSROOMS.NAME,
        },
      },
      {
        path: RouteList.LMS.CENTER.CLASSROOMS.PATH,
        name: RouteList.LMS.CENTER.CLASSROOMS.NAME,
        component: LmsClassroomView,
        meta: {
          title: 'Classrooms',
          feature: [FeatureEnum.LMS_CENTER_CLASSROOMS, FeatureEnum.LMS_CENTER, FeatureEnum.LMS],
          permission: [
            AbilityEnum.LMS_CENTER_CLASSROOM_VIEW,
            AbilityEnum.LMS_CENTER_CLASSROOM,
            AbilityEnum.LMS_CENTER,
            AbilityEnum.LMS,
          ],
        },
      },
      {
        path: RouteList.LMS.STATISTICS.INDEX.PATH,
        name: RouteList.LMS.STATISTICS.INDEX.NAME,
        meta: {
          feature: [FeatureEnum.LMS_STATISTICS, FeatureEnum.LMS],
          permission: [AbilityEnum.LMS_STATISTIC, AbilityEnum.LMS],
        },
        redirect: {
          name: RouteList.LMS.STATISTICS.OVERVIEW.NAME,
        },
      },
      {
        path: RouteList.LMS.STATISTICS.OVERVIEW.PATH,
        name: RouteList.LMS.STATISTICS.OVERVIEW.NAME,
        component: StatisticsOverview,
        meta: {
          title: 'Statistics Overview',
          feature: [
            FeatureEnum.LMS_STATISTICS_OVERVIEW,
            FeatureEnum.LMS_STATISTICS,
            FeatureEnum.LMS,
          ],
          permission: [
            AbilityEnum.LMS_STATISTIC_QUICK_OVERVIEW,
            AbilityEnum.LMS_STATISTIC,
            AbilityEnum.LMS,
          ],
        },
      },
      {
        path: RouteList.LMS.STATISTICS.REPORTS.INDEX.PATH,
        name: RouteList.LMS.STATISTICS.REPORTS.INDEX.NAME,
        component: StatisticsReports,
        meta: {
          title: 'Statistics Reports',
          feature: [FeatureEnum.LMS_STATISTICS_REPORT, FeatureEnum.LMS_STATISTICS, FeatureEnum.LMS],
          permission: [
            AbilityEnum.LMS_STATISTIC_REPORT,
            AbilityEnum.LMS_STATISTIC,
            AbilityEnum.LMS,
          ],
        },
      },
      {
        path: RouteList.LMS.STATISTICS.REPORTS.REPORT.PATH,
        name: RouteList.LMS.STATISTICS.REPORTS.REPORT.NAME,
        component: StatisticsReportsLeadList,
        meta: {
          title: 'Report',
          feature: [FeatureEnum.LMS_STATISTICS_REPORT, FeatureEnum.LMS_STATISTICS, FeatureEnum.LMS],
          permission: [
            AbilityEnum.LMS_STATISTIC_REPORT,
            AbilityEnum.LMS_STATISTIC,
            AbilityEnum.LMS,
          ],
        },
      },
      {
        path: RouteList.LMS.STATISTICS.REPORTS.EMBEDDED_REPORT.PATH,
        name: RouteList.LMS.STATISTICS.REPORTS.EMBEDDED_REPORT.NAME,
        component: StatisticsEmbeddedReport,
        meta: {
          title: 'Embedded Report',
          feature: [FeatureEnum.LMS_STATISTICS_REPORT, FeatureEnum.LMS_STATISTICS, FeatureEnum.LMS],
          permission: [
            AbilityEnum.LMS_STATISTIC_REPORT,
            AbilityEnum.LMS_STATISTIC,
            AbilityEnum.LMS,
          ],
        },
      },
      {
        path: RouteList.LMS.CONVERSATIONS.INDEX.PATH,
        name: RouteList.LMS.CONVERSATIONS.INDEX.NAME,
        component: DivComponent,
        meta: {
          feature: [FeatureEnum.LMS_CONVERSATIONS, FeatureEnum.LMS],
          permission: [AbilityEnum.LMS_CONVERSATION, AbilityEnum.LMS],
        },
      },
      {
        path: RouteList.LMS.CONVERSATIONS.CALLS.PATH,
        name: RouteList.LMS.CONVERSATIONS.CALLS.NAME,
        component: CallList,
        meta: {
          title: 'Conversations: Calls',
          feature: [
            FeatureEnum.LMS_CONVERSATIONS_CALL,
            FeatureEnum.LMS_CONVERSATIONS,
            FeatureEnum.LMS,
          ],
          permission: [
            AbilityEnum.LMS_CONVERSATION_CALL,
            AbilityEnum.LMS_CONVERSATION,
            AbilityEnum.LMS,
          ],
        },
      },
      {
        path: RouteList.LMS.CONVERSATIONS.SMS.PATH,
        name: RouteList.LMS.CONVERSATIONS.SMS.NAME,
        component: SmsList,
        meta: {
          title: 'Conversations: SMS',
          feature: [
            FeatureEnum.LMS_CONVERSATIONS_SMS,
            FeatureEnum.LMS_CONVERSATIONS,
            FeatureEnum.LMS,
          ],
          permission: [
            AbilityEnum.LMS_CONVERSATION_SMS,
            AbilityEnum.LMS_CONVERSATION,
            AbilityEnum.LMS,
          ],
        },
      },
      {
        path: RouteList.LMS.CONVERSATIONS.EMAILS.PATH,
        name: RouteList.LMS.CONVERSATIONS.EMAILS.NAME,
        component: EmailList,
        meta: {
          title: 'Conversations: Emails',
          feature: [
            FeatureEnum.LMS_CONVERSATIONS_EMAIL,
            FeatureEnum.LMS_CONVERSATIONS,
            FeatureEnum.LMS,
          ],
          permission: [
            AbilityEnum.LMS_CONVERSATION_EMAIL,
            AbilityEnum.LMS_CONVERSATION,
            AbilityEnum.LMS,
          ],
        },
      },
      {
        path: RouteList.LMS.CONVERSATIONS.MESSENGER.PATH,
        name: RouteList.LMS.CONVERSATIONS.MESSENGER.NAME,
        component: MessengerList,
        meta: {
          title: 'Conversations: Messenger',
          feature: [
            FeatureEnum.LMS_CONVERSATIONS_MESSENGER,
            FeatureEnum.LMS_CONVERSATIONS,
            FeatureEnum.LMS,
          ],
          permission: [
            AbilityEnum.LMS_CONVERSATION_MESSENGER,
            AbilityEnum.LMS_CONVERSATION,
            AbilityEnum.LMS,
          ],
        },
      },
      {
        path: RouteList.LMS.MARKETING.INDEX.PATH,
        name: RouteList.LMS.MARKETING.INDEX.NAME,
        component: DivComponent,
        meta: {
          feature: [FeatureEnum.LMS_MARKETING_AUTOMATION, FeatureEnum.LMS],
          permission: [AbilityEnum.LMS_MARKETING_AUTOMATION, AbilityEnum.LMS],
        },
        redirect: {
          name: RouteList.LMS.MARKETING.WORKFLOWS.INDEX.NAME,
        },
      },
      {
        path: RouteList.LMS.MARKETING.WORKFLOWS.INDEX.PATH,
        name: RouteList.LMS.MARKETING.WORKFLOWS.INDEX.NAME,
        component: WorkflowList,
        meta: {
          title: 'Workflows',
          feature: [
            FeatureEnum.LMS_MARKETING_AUTOMATION_WORKFLOW,
            FeatureEnum.LMS_MARKETING_AUTOMATION,
            FeatureEnum.LMS,
          ],
          permission: [
            AbilityEnum.LMS_MARKETING_AUTOMATION_WORKFLOW,
            AbilityEnum.LMS_MARKETING_AUTOMATION,
            AbilityEnum.LMS,
          ],
        },
      },
      {
        path: RouteList.LMS.MARKETING.WORKFLOWS.CREATE.PATH,
        name: RouteList.LMS.MARKETING.WORKFLOWS.CREATE.NAME,
        component: CreateWorkflow,
        meta: {
          title: 'New Workflow',
          feature: [
            FeatureEnum.LMS_MARKETING_AUTOMATION_WORKFLOW,
            FeatureEnum.LMS_MARKETING_AUTOMATION,
            FeatureEnum.LMS,
          ],
          permission: [
            AbilityEnum.LMS_MARKETING_AUTOMATION_WORKFLOW_CREATE,
            AbilityEnum.LMS_MARKETING_AUTOMATION_WORKFLOW,
            AbilityEnum.LMS_MARKETING_AUTOMATION,
            AbilityEnum.LMS,
          ],
        },
      },
      {
        path: RouteList.LMS.MARKETING.WORKFLOWS.WORKFLOW.INDEX.PATH,
        name: RouteList.LMS.MARKETING.WORKFLOWS.WORKFLOW.INDEX.NAME,
        component: WorkflowSingle,
        meta: {
          title: 'Workflow',
          feature: [
            FeatureEnum.LMS_MARKETING_AUTOMATION_WORKFLOW,
            FeatureEnum.LMS_MARKETING_AUTOMATION,
            FeatureEnum.LMS,
          ],
          permission: [
            AbilityEnum.LMS_MARKETING_AUTOMATION_WORKFLOW_VIEW,
            AbilityEnum.LMS_MARKETING_AUTOMATION_WORKFLOW,
            AbilityEnum.LMS_MARKETING_AUTOMATION,
            AbilityEnum.LMS,
          ],
        },
      },
      {
        path: RouteList.LMS.MARKETING.WORKFLOWS.WORKFLOW.EDIT.PATH,
        name: RouteList.LMS.MARKETING.WORKFLOWS.WORKFLOW.EDIT.NAME,
        component: CreateWorkflow,
        meta: {
          title: 'Edit Workflow',
          feature: [
            FeatureEnum.LMS_MARKETING_AUTOMATION_WORKFLOW,
            FeatureEnum.LMS_MARKETING_AUTOMATION,
            FeatureEnum.LMS,
          ],
          permission: [
            AbilityEnum.LMS_MARKETING_AUTOMATION_WORKFLOW_UPDATE,
            AbilityEnum.LMS_MARKETING_AUTOMATION_WORKFLOW_VIEW,
            AbilityEnum.LMS_MARKETING_AUTOMATION_WORKFLOW,
            AbilityEnum.LMS_MARKETING_AUTOMATION,
            AbilityEnum.LMS,
          ],
        },
      },
      {
        path: RouteList.LMS.MARKETING.TEMPLATES.INDEX.PATH,
        name: RouteList.LMS.MARKETING.TEMPLATES.INDEX.NAME,
        component: DivComponent,
        meta: {
          feature: [
            FeatureEnum.LMS_MARKETING_AUTOMATION_TEMPLATE,
            FeatureEnum.LMS_MARKETING_AUTOMATION,
            FeatureEnum.LMS,
          ],
          permission: [
            AbilityEnum.LMS_MARKETING_AUTOMATION_TEMPLATE,
            AbilityEnum.LMS_MARKETING_AUTOMATION,
            AbilityEnum.LMS,
          ],
        },
        redirect: {
          name: RouteList.LMS.MARKETING.TEMPLATES.EMAIL.INDEX.NAME,
        },
      },
      {
        path: RouteList.LMS.MARKETING.TEMPLATES.EMAIL.INDEX.PATH,
        name: RouteList.LMS.MARKETING.TEMPLATES.EMAIL.INDEX.NAME,
        component: TemplatesList.Email,
        meta: {
          title: 'Templates: Email',
          feature: [
            FeatureEnum.LMS_MARKETING_AUTOMATION_EMAIL_TEMPLATE,
            FeatureEnum.LMS_MARKETING_AUTOMATION_TEMPLATE,
            FeatureEnum.LMS_MARKETING_AUTOMATION,
            FeatureEnum.LMS,
          ],
          permission: [
            AbilityEnum.LMS_MARKETING_AUTOMATION_EMAIL_TEMPLATE,
            AbilityEnum.LMS_MARKETING_AUTOMATION_TEMPLATE,
            AbilityEnum.LMS_MARKETING_AUTOMATION,
            AbilityEnum.LMS,
          ],
        },
      },
      {
        path: RouteList.LMS.MARKETING.TEMPLATES.SMS.INDEX.PATH,
        name: RouteList.LMS.MARKETING.TEMPLATES.SMS.INDEX.NAME,
        component: TemplatesList.Sms,
        meta: {
          title: 'Templates: SMS',
          feature: [
            FeatureEnum.LMS_MARKETING_AUTOMATION_SMS_TEMPLATE,
            FeatureEnum.LMS_MARKETING_AUTOMATION_TEMPLATE,
            FeatureEnum.LMS_MARKETING_AUTOMATION,
            FeatureEnum.LMS,
          ],
          permission: [
            AbilityEnum.LMS_MARKETING_AUTOMATION_SMS_TEMPLATE,
            AbilityEnum.LMS_MARKETING_AUTOMATION_TEMPLATE,
            AbilityEnum.LMS_MARKETING_AUTOMATION,
            AbilityEnum.LMS,
          ],
        },
      },
      {
        path: RouteList.LMS.MARKETING.TEMPLATES.TASK.INDEX.PATH,
        name: RouteList.LMS.MARKETING.TEMPLATES.TASK.INDEX.NAME,
        component: TemplatesList.Task,
        meta: {
          title: 'Templates: Task',
          feature: [
            FeatureEnum.SETTINGS_TASKS,
            FeatureEnum.LMS_MARKETING_AUTOMATION_TEMPLATE,
            FeatureEnum.LMS_MARKETING_AUTOMATION,
            FeatureEnum.LMS,
          ],
          permission: [
            AbilityEnum.SETTING_TASKS_TEMPLATES,
            AbilityEnum.LMS_MARKETING_AUTOMATION_TEMPLATE,
            AbilityEnum.LMS_MARKETING_AUTOMATION,
            AbilityEnum.LMS,
          ],
        },
      },
      {
        path: RouteList.LMS.MARKETING.TEMPLATES.FACEBOOK.INDEX.PATH,
        name: RouteList.LMS.MARKETING.TEMPLATES.FACEBOOK.INDEX.NAME,
        component: TemplatesList.Facebook,
        meta: {
          title: 'Templates: Facebook',
          feature: [
            // FeatureEnum.SETTINGS_FACEBOOK, // disabled until backend adds it? page doesnt load otherwise
            FeatureEnum.LMS_MARKETING_AUTOMATION_TEMPLATE,
            FeatureEnum.LMS_MARKETING_AUTOMATION,
            FeatureEnum.LMS,
          ],
          permission: [
            AbilityEnum.LMS_MARKETING_AUTOMATION_FACEBOOK_MESSAGE_TEMPLATE,
            AbilityEnum.LMS_MARKETING_AUTOMATION_TEMPLATE,
            AbilityEnum.LMS_MARKETING_AUTOMATION,
            AbilityEnum.LMS,
          ],
        },
      },
      {
        path: RouteList.LMS.MARKETING.CAMPAIGNS.INDEX.PATH,
        name: RouteList.LMS.MARKETING.CAMPAIGNS.INDEX.NAME,
        component: DivComponent,
        meta: {
          feature: [
            FeatureEnum.LMS_MARKETING_AUTOMATION_CAMPAIGN,
            FeatureEnum.LMS_MARKETING_AUTOMATION,
            FeatureEnum.LMS,
          ],
          permission: [
            AbilityEnum.LMS_MARKETING_AUTOMATION_CAMPAIGN,
            AbilityEnum.LMS_MARKETING_AUTOMATION,
            AbilityEnum.LMS,
          ],
        },
        redirect: {
          name: RouteList.LMS.MARKETING.CAMPAIGNS.SENT.NAME,
        },
      },
      {
        path: RouteList.LMS.MARKETING.CAMPAIGNS.SENT.PATH,
        name: RouteList.LMS.MARKETING.CAMPAIGNS.SENT.NAME,
        component: CampaignList,
        meta: {
          title: 'Sent Campaigns',
          feature: [
            FeatureEnum.LMS_MARKETING_AUTOMATION_CAMPAIGN,
            FeatureEnum.LMS_MARKETING_AUTOMATION,
            FeatureEnum.LMS,
          ],
          permission: [
            AbilityEnum.LMS_MARKETING_AUTOMATION_CAMPAIGN,
            AbilityEnum.LMS_MARKETING_AUTOMATION,
            AbilityEnum.LMS,
          ],
        },
      },
      {
        path: RouteList.LMS.MARKETING.CAMPAIGNS.DRAFT.PATH,
        name: RouteList.LMS.MARKETING.CAMPAIGNS.DRAFT.NAME,
        component: CampaignList,
        meta: {
          title: 'Draft Campaigns',
          feature: [
            FeatureEnum.LMS_MARKETING_AUTOMATION_CAMPAIGN,
            FeatureEnum.LMS_MARKETING_AUTOMATION,
            FeatureEnum.LMS,
          ],
          permission: [
            AbilityEnum.LMS_MARKETING_AUTOMATION_CAMPAIGN,
            AbilityEnum.LMS_MARKETING_AUTOMATION,
            AbilityEnum.LMS,
          ],
        },
      },
      {
        path: RouteList.LMS.MARKETING.CAMPAIGNS.SCHEDULED.PATH,
        name: RouteList.LMS.MARKETING.CAMPAIGNS.SCHEDULED.NAME,
        component: CampaignList,
        meta: {
          title: 'Scheduled Campaigns',
          feature: [
            FeatureEnum.LMS_MARKETING_AUTOMATION_CAMPAIGN,
            FeatureEnum.LMS_MARKETING_AUTOMATION,
            FeatureEnum.LMS,
          ],
          permission: [
            AbilityEnum.LMS_MARKETING_AUTOMATION_CAMPAIGN,
            AbilityEnum.LMS_MARKETING_AUTOMATION,
            AbilityEnum.LMS,
          ],
        },
      },
      {
        path: RouteList.LMS.MARKETING.CAMPAIGNS.CREATE.PATH,
        name: RouteList.LMS.MARKETING.CAMPAIGNS.CREATE.NAME,
        component: CampaignForm,
        meta: {
          title: 'New Campaign',
          feature: [
            FeatureEnum.LMS_MARKETING_AUTOMATION_CAMPAIGN,
            FeatureEnum.LMS_MARKETING_AUTOMATION,
            FeatureEnum.LMS,
          ],
          permission: [
            AbilityEnum.LMS_MARKETING_AUTOMATION_CAMPAIGN_CREATE,
            AbilityEnum.LMS_MARKETING_AUTOMATION_CAMPAIGN,
            AbilityEnum.LMS_MARKETING_AUTOMATION,
            AbilityEnum.LMS,
          ],
        },
      },
      {
        path: RouteList.LMS.MARKETING.CAMPAIGNS.SHOW.PATH,
        name: RouteList.LMS.MARKETING.CAMPAIGNS.SHOW.NAME,
        component: CampaignShow,
        meta: {
          title: 'Campaign',
          feature: [
            FeatureEnum.LMS_MARKETING_AUTOMATION_CAMPAIGN,
            FeatureEnum.LMS_MARKETING_AUTOMATION,
            FeatureEnum.LMS,
          ],
          permission: [
            AbilityEnum.LMS_MARKETING_AUTOMATION_CAMPAIGN_VIEW,
            AbilityEnum.LMS_MARKETING_AUTOMATION_CAMPAIGN,
            AbilityEnum.LMS_MARKETING_AUTOMATION,
            AbilityEnum.LMS,
          ],
        },
      },
      {
        path: RouteList.LMS.MARKETING.CAMPAIGNS.EDIT.PATH,
        name: RouteList.LMS.MARKETING.CAMPAIGNS.EDIT.NAME,
        component: CampaignForm,
        meta: {
          title: 'Draft Campaign',
          feature: [
            FeatureEnum.LMS_MARKETING_AUTOMATION_CAMPAIGN,
            FeatureEnum.LMS_MARKETING_AUTOMATION,
            FeatureEnum.LMS,
          ],
          permission: [
            AbilityEnum.LMS_MARKETING_AUTOMATION_CAMPAIGN_UPDATE,
            AbilityEnum.LMS_MARKETING_AUTOMATION_CAMPAIGN,
            AbilityEnum.LMS_MARKETING_AUTOMATION,
            AbilityEnum.LMS,
          ],
        },
      },
      {
        path: RouteList.LMS.MARKETING.FORMS.INDEX.PATH,
        name: RouteList.LMS.MARKETING.FORMS.INDEX.NAME,
        component: FormsList,
        meta: {
          feature: [
            FeatureEnum.LMS_MARKETING_AUTOMATION_FORMS,
            FeatureEnum.LMS_MARKETING_AUTOMATION,
            FeatureEnum.LMS,
          ],
          permission: [
            AbilityEnum.LMS_MARKETING_AUTOMATION_FORMS,
            AbilityEnum.LMS_MARKETING_AUTOMATION,
            AbilityEnum.LMS,
          ],
        },
      },
      {
        path: RouteList.LMS.MARKETING.FORMS.PROFILE.PATH,
        name: RouteList.LMS.MARKETING.FORMS.PROFILE.NAME,
        component: FormProfile,
        meta: {
          feature: [
            FeatureEnum.LMS_MARKETING_AUTOMATION_FORMS,
            FeatureEnum.LMS_MARKETING_AUTOMATION,
            FeatureEnum.LMS,
          ],
          permission: [
            AbilityEnum.LMS_MARKETING_AUTOMATION_FORMS_VIEW,
            AbilityEnum.LMS_MARKETING_AUTOMATION,
            AbilityEnum.LMS,
          ],
        },
      },
      {
        path: RouteList.LMS.MARKETING.FORMS.CREATE.PATH,
        name: RouteList.LMS.MARKETING.FORMS.CREATE.NAME,
        component: FormCreateEdit,
        meta: {
          feature: [
            FeatureEnum.LMS_MARKETING_AUTOMATION_FORMS,
            FeatureEnum.LMS_MARKETING_AUTOMATION,
            FeatureEnum.LMS,
          ],
          permission: [
            AbilityEnum.LMS_MARKETING_AUTOMATION_FORMS_CREATE,
            AbilityEnum.LMS_MARKETING_AUTOMATION,
            AbilityEnum.LMS,
          ],
        },
      },
      {
        path: RouteList.LMS.MARKETING.FORMS.EDIT.PATH,
        name: RouteList.LMS.MARKETING.FORMS.EDIT.NAME,
        component: FormCreateEdit,
        meta: {
          feature: [
            FeatureEnum.LMS_MARKETING_AUTOMATION_FORMS,
            FeatureEnum.LMS_MARKETING_AUTOMATION,
            FeatureEnum.LMS,
          ],
          permission: [
            AbilityEnum.LMS_MARKETING_AUTOMATION_FORMS_UPDATE,
            AbilityEnum.LMS_MARKETING_AUTOMATION,
            AbilityEnum.LMS,
          ],
        },
      },
      {
        path: RouteList.LMS.INTELLIFORMS.INDEX.PATH,
        name: RouteList.LMS.INTELLIFORMS.INDEX.NAME,
        meta: {
          feature: [FeatureEnum.LMS_INTELLIFORMS, FeatureEnum.LMS],
          permission: [AbilityEnum.LMS_INTELLIFORM, AbilityEnum.LMS],
        },
        redirect: {
          name: RouteList.LMS.INTELLIFORMS.PACKAGES.INDEX.NAME,
        },
      },
      {
        path: RouteList.LMS.INTELLIFORMS.PACKAGES.INDEX.PATH,
        name: RouteList.LMS.INTELLIFORMS.PACKAGES.INDEX.NAME,
        component: IntelliFormsPackagesView,
        meta: {
          title: 'Packages',
          feature: [
            FeatureEnum.LMS_INTELLIFORMS_PACKAGE,
            FeatureEnum.LMS_INTELLIFORMS,
            FeatureEnum.LMS,
          ],
          permission: [
            AbilityEnum.LMS_INTELLIFORM_PACKAGE,
            AbilityEnum.LMS_INTELLIFORM,
            AbilityEnum.LMS,
          ],
        },
      },
      {
        path: RouteList.LMS.INTELLIFORMS.PACKAGES.CREATE.PATH,
        name: RouteList.LMS.INTELLIFORMS.PACKAGES.CREATE.NAME,
        component: IntelliFormsPackageFormView,
        meta: {
          title: 'New Package',
          feature: [
            FeatureEnum.LMS_INTELLIFORMS_PACKAGE,
            FeatureEnum.LMS_INTELLIFORMS,
            FeatureEnum.LMS,
          ],
          permission: [
            AbilityEnum.LMS_INTELLIFORM_PACKAGE_CREATE,
            AbilityEnum.LMS_INTELLIFORM_PACKAGE,
            AbilityEnum.LMS_INTELLIFORM,
            AbilityEnum.LMS,
          ],
        },
      },
      {
        path: RouteList.LMS.INTELLIFORMS.PACKAGES.PACKAGE.INDEX.PATH,
        name: RouteList.LMS.INTELLIFORMS.PACKAGES.PACKAGE.INDEX.NAME,
        component: IntelliFormsPackageView,
        meta: {
          title: 'Package',
          feature: [
            FeatureEnum.LMS_INTELLIFORMS_PACKAGE,
            FeatureEnum.LMS_INTELLIFORMS,
            FeatureEnum.LMS,
          ],
          permission: [
            AbilityEnum.LMS_INTELLIFORM_PACKAGE_VIEW,
            AbilityEnum.LMS_INTELLIFORM_PACKAGE,
            AbilityEnum.LMS_INTELLIFORM,
            AbilityEnum.LMS,
          ],
        },
      },
      {
        path: RouteList.LMS.INTELLIFORMS.PACKAGES.PACKAGE.EDIT.PATH,
        name: RouteList.LMS.INTELLIFORMS.PACKAGES.PACKAGE.EDIT.NAME,
        component: IntelliFormsPackageFormView,
        meta: {
          title: 'Edit Package',
          feature: [
            FeatureEnum.LMS_INTELLIFORMS_PACKAGE,
            FeatureEnum.LMS_INTELLIFORMS,
            FeatureEnum.LMS,
          ],
          permission: [
            AbilityEnum.LMS_INTELLIFORM_PACKAGE_UPDATE,
            AbilityEnum.LMS_INTELLIFORM_PACKAGE_VIEW,
            AbilityEnum.LMS_INTELLIFORM_PACKAGE,
            AbilityEnum.LMS_INTELLIFORM,
            AbilityEnum.LMS,
          ],
        },
      },
      {
        path: RouteList.LMS.INTELLIFORMS.DOCUMENTS.INDEX.PATH,
        name: RouteList.LMS.INTELLIFORMS.DOCUMENTS.INDEX.NAME,
        component: IntelliFormsDocumentsView,
        meta: {
          title: 'Documents',
          feature: [
            FeatureEnum.LMS_INTELLIFORMS_DOCUMENT,
            FeatureEnum.LMS_INTELLIFORMS,
            FeatureEnum.LMS,
          ],
          permission: [
            AbilityEnum.LMS_INTELLIFORM_DOCUMENT,
            AbilityEnum.LMS_INTELLIFORM,
            AbilityEnum.LMS,
          ],
        },
      },
      {
        path: RouteList.LMS.INTELLIFORMS.DOCUMENTS.CREATE.PATH,
        name: RouteList.LMS.INTELLIFORMS.DOCUMENTS.CREATE.NAME,
        component: IntelliFormsDocumentFormView,
        meta: {
          title: 'New Document',
          feature: [
            FeatureEnum.LMS_INTELLIFORMS_DOCUMENT,
            FeatureEnum.LMS_INTELLIFORMS,
            FeatureEnum.LMS,
          ],
          permission: [
            AbilityEnum.LMS_INTELLIFORM_DOCUMENT_CREATE,
            AbilityEnum.LMS_INTELLIFORM_DOCUMENT,
            AbilityEnum.LMS_INTELLIFORM,
            AbilityEnum.LMS,
          ],
        },
      },
      {
        path: RouteList.LMS.INTELLIFORMS.DOCUMENTS.DOCUMENT.EDIT.PATH,
        name: RouteList.LMS.INTELLIFORMS.DOCUMENTS.DOCUMENT.EDIT.NAME,
        component: IntelliFormsDocumentFormView,
        meta: {
          title: 'Edit Document',
          feature: [
            FeatureEnum.LMS_INTELLIFORMS_DOCUMENT,
            FeatureEnum.LMS_INTELLIFORMS,
            FeatureEnum.LMS,
          ],
          permission: [
            AbilityEnum.LMS_INTELLIFORM_DOCUMENT_VIEW,
            AbilityEnum.LMS_INTELLIFORM_DOCUMENT,
            AbilityEnum.LMS_INTELLIFORM,
            AbilityEnum.LMS,
          ],
        },
      },
      {
        path: RouteList.LMS.TOOLS.INDEX.PATH,
        name: RouteList.LMS.TOOLS.INDEX.NAME,
        redirect: {
          name: RouteList.LMS.TOOLS.EXPORT.INDEX.NAME,
        },
        meta: {
          feature: [FeatureEnum.LMS_TOOLS, FeatureEnum.LMS],
          permission: [AbilityEnum.LMS_TOOL, AbilityEnum.LMS],
        },
      },
      {
        path: RouteList.LMS.TOOLS.EXPORT.INDEX.PATH,
        name: RouteList.LMS.TOOLS.EXPORT.INDEX.NAME,
        component: ExportTools.List,
        meta: {
          feature: [FeatureEnum.LMS_TOOLS_EXPORT, FeatureEnum.LMS_TOOLS, FeatureEnum.LMS],
          permission: [AbilityEnum.LMS_TOOL_EXPORT, AbilityEnum.LMS_TOOL, AbilityEnum.LMS],
        },
      },
      {
        path: RouteList.LMS.TOOLS.EXPORT.CREATE.PATH,
        name: RouteList.LMS.TOOLS.EXPORT.CREATE.NAME,
        component: ExportTools.Create,
        meta: {
          feature: [FeatureEnum.LMS_TOOLS_EXPORT, FeatureEnum.LMS_TOOLS, FeatureEnum.LMS],
          permission: [AbilityEnum.LMS_TOOL_EXPORT, AbilityEnum.LMS_TOOL, AbilityEnum.LMS],
        },
      },
      {
        path: RouteList.LMS.TOOLS.DUPLICATES.INDEX.PATH,
        name: RouteList.LMS.TOOLS.DUPLICATES.INDEX.NAME,
        component: DuplicatesTools.List,
        meta: {
          feature: [FeatureEnum.LMS_TOOLS_DUPLICATE, FeatureEnum.LMS_TOOLS, FeatureEnum.LMS],
          permission: [AbilityEnum.LMS_TOOL_DUPLICATE, AbilityEnum.LMS_TOOL, AbilityEnum.LMS],
        },
      },
      {
        path: RouteList.LMS.TOOLS.DUPLICATES.CUSTOM.PATH,
        name: RouteList.LMS.TOOLS.DUPLICATES.CUSTOM.NAME,
        component: DuplicatesTools.Custom,
        meta: {
          feature: [FeatureEnum.LMS_TOOLS_DUPLICATE, FeatureEnum.LMS_TOOLS, FeatureEnum.LMS],
          permission: [AbilityEnum.LMS_TOOL_DUPLICATE, AbilityEnum.LMS_TOOL, AbilityEnum.LMS],
        },
      },
      {
        path: RouteList.LMS.TOOLS.DUPLICATES.MERGE.PATH,
        name: RouteList.LMS.TOOLS.DUPLICATES.MERGE.NAME,
        component: DuplicatesTools.Merge,
        meta: {
          feature: [FeatureEnum.LMS_TOOLS_DUPLICATE, FeatureEnum.LMS_TOOLS, FeatureEnum.LMS],
          permission: [
            // AbilityEnum.LMS_TOOL_DUPLICATE,
            AbilityEnum.LMS_TOOL,
            AbilityEnum.LMS,
          ],
        },
      },
    ],
  },
]
