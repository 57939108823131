<template>
  <div v-if="meta && meta.total > 0" class="row">
    <div class="col">
      <div class="d-flex justify-content-center">
        <a-pagination
          v-model:current="page"
          :page-size="meta.per_page"
          :show-size-changer="false"
          :show-total="showTotal"
          :total="meta.total"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { PropType } from 'vue'
import { PaginationMeta } from '@/api/PaginatedResponse'
import { asyncComponentWrapper } from '@/composables/useReloadModal'

const APagination = asyncComponentWrapper(() => import('ant-design-vue/es/pagination'))

defineProps({
  meta: {
    type: Object as PropType<PaginationMeta | undefined | null>,
    default: null,
  },
})

const page = defineModel({ type: Number as PropType<number | undefined> })

const showTotal = (total: number, range: number[]) => {
  return `${range[0]}-${range[1]} of ${total} items`
}
</script>
