import type { RouteRecordRaw } from 'vue-router'
import { RouteList } from '@/routes/RouteList'
import UIComponentView from '@/views/UI/UIComponentView.vue'
import ProjectIcons from '@/views/UI/ProjectIcons.vue'

export default <RouteRecordRaw[]>[
  {
    path: RouteList.UI.INDEX.PATH,
    name: RouteList.UI.INDEX.NAME,
    component: UIComponentView,
  },
  {
    path: RouteList.UI.ICONS.PATH,
    name: RouteList.UI.ICONS.NAME,
    component: ProjectIcons,
  },
]
