import { AxiosResponse, AxiosRequestConfig } from 'axios'
import client from '@/api/client'
import { UserItemResponse } from '@/dto/Auth/UserDto'
import { AcceptInvitationRequestDto, PasswordResetRequestDto } from '@/dto/Auth/Invitation/Request'
import { AbilityResponse } from '@/dto/Auth/PermissionDto'
import { Response } from '@/api/PaginatedResponse'
import { UserPreferenceResponseDTO } from '@/dto/Auth/Preference/Response'
import { UpdateUserPreferenceRequestDTO } from '@/dto/Auth/Preference/Request'
import { getSocialRedirectUri } from '@/api/Auth/social'
import {
  AUTHENTICATE_URL,
  AUTHENTICATE_SOCIAL_URL,
  REFRESH_TOKEN_URL,
  REVOKE_TOKEN_URL,
  CURRENT_USER_URL,
  ABILITIES_URL,
} from '@/api/Auth/urlConst'
import { AuthTokenResponse } from '@/dto/Auth/TokensDto'

export interface AbortableAxiosRequestConfig extends AxiosRequestConfig {
  _cannotAbort?: boolean
}

const config: AbortableAxiosRequestConfig = {
  _cannotAbort: true,
}

// TODO rename and move to dto folder
type UserResponse = {
  data: UserItemResponse
}

// TODO rename and move to dto folder
export type AbilitiesResponse = {
  data: AbilityResponse[]
}

// TODO rename and move to dto folder
export type PermissionUpdateRequest = {
  name: string
  entity_type: string | null
  entity_id: string | null
  allow: boolean
}

export function impersonate(userId: string): AuthTokenResponse {
  return client.post(`/auth/admin/become/${userId}`, undefined, config)
}

export function authenticate(username: string, password: string): AuthTokenResponse {
  return client.post(AUTHENTICATE_URL, { username, password }, config)
}

export function authenticateSocial(provider: string, code: string): AuthTokenResponse {
  const redirectUri = getSocialRedirectUri(provider)

  return client.post(AUTHENTICATE_SOCIAL_URL, { provider, code, redirect_uri: redirectUri }, config)
}

export function currentUser(): Promise<AxiosResponse<UserResponse>> {
  return client.get(CURRENT_USER_URL, config)
}

export function refreshTokens(refreshToken: string): AuthTokenResponse {
  return client.post(
    REFRESH_TOKEN_URL,
    {
      refresh_token: refreshToken,
    },
    config,
  )
}

export function revokeToken(): Promise<AxiosResponse> {
  return client.post(REVOKE_TOKEN_URL, undefined, config)
}

export function getAbilities(level: number): Promise<AxiosResponse<AbilitiesResponse>> {
  return client.get(ABILITIES_URL, {
    params: {
      level,
    },
  })
}

export function acceptInvitation(
  invitationId: string,
  request: AcceptInvitationRequestDto,
): Promise<AxiosResponse> {
  return client.put(`/business/invites/${invitationId}/accept`, request, config)
}

export function acceptAdminInvitation(
  invitationId: string,
  request: AcceptInvitationRequestDto,
): Promise<AxiosResponse> {
  return client.put(`/admin/invites/${invitationId}/accept`, request, config)
}

export function forgotPassword(request: { email: string }): Promise<AxiosResponse> {
  return client.post('/auth/password/forgot', request, config)
}

export function resetPassword(request: PasswordResetRequestDto): Promise<AxiosResponse> {
  return client.post(`/auth/password/reset`, request, config)
}

export function getUserPreferences(): Promise<AxiosResponse<Response<UserPreferenceResponseDTO>>> {
  return client.get('/user/users/me/preferences', config)
}

export function updateUserPreference(
  request: UpdateUserPreferenceRequestDTO,
): Promise<AxiosResponse<Response<UserPreferenceResponseDTO>>> {
  return client.put('/user/users/me/preferences', request, config)
}
