export default {
  LMS: {
    INDEX: {
      PATH: '/',
      NAME: 'index',
    },
    LMS: {
      PATH: '/b/:businessSlug/lms/',
      NAME: 'lms',
    },
    LEADS: {
      INDEX: {
        PATH: 'leads/:status?',
        NAME: 'lms.leads.index',
      },
      LEAD: {
        PATH: 'leads/:leadId(\\d+)',
        NAME: 'lms.leads.show',
      },
    },
    CENTER: {
      INDEX: {
        PATH: 'center',
        NAME: 'lms.center.index',
      },
      CLASSROOMS: {
        PATH: 'center/classrooms',
        NAME: 'lms.center.classrooms',
      },
    },
    STATISTICS: {
      INDEX: {
        PATH: 'statistics',
        NAME: 'lms.statistics.index',
      },
      OVERVIEW: {
        PATH: 'statistics/overview',
        NAME: 'lms.statistics.overview',
      },
      REPORTS: {
        INDEX: {
          PATH: 'statistics/reports',
          NAME: 'lms.statistics.index',
        },
        REPORT: {
          PATH: 'statistics/report/:reportId',
          NAME: 'lms.statistics.report',
        },
        EMBEDDED_REPORT: {
          PATH: 'statistics/report/embed/:reportId',
          NAME: 'lms.statistics.embed.report',
        },
      },
    },
    CONVERSATIONS: {
      INDEX: {
        PATH: 'conversations',
        NAME: 'lms.conversations.index',
      },
      CALLS: {
        PATH: 'conversations/calls',
        NAME: 'lms.conversations.calls',
      },
      SMS: {
        PATH: 'conversations/sms',
        NAME: 'lms.conversations.sms',
      },
      EMAILS: {
        PATH: 'conversations/emails',
        NAME: 'lms.conversations.emails',
      },
      MESSENGER: {
        PATH: 'conversations/messenger',
        NAME: 'lms.conversations.messenger',
      },
    },
    MARKETING: {
      INDEX: {
        PATH: 'marketing',
        NAME: 'lms.marketing.index',
      },
      WORKFLOWS: {
        INDEX: {
          PATH: 'marketing/workflows',
          NAME: 'lms.marketing.workflows',
        },
        CREATE: {
          PATH: 'marketing/workflows/create',
          NAME: 'lms.marketing.workflows.create',
        },
        WORKFLOW: {
          INDEX: {
            PATH: 'marketing/workflows/:workflowId(\\d+)',
            NAME: 'lms.marketing.workflows.show',
          },
          EDIT: {
            PATH: 'marketing/workflows/:workflowId(\\d+)/edit',
            NAME: 'lms.marketing.workflows.edit',
          },
        },
      },
      TEMPLATES: {
        INDEX: {
          PATH: 'marketing/templates',
          NAME: 'lms.marketing.templates',
        },
        SMS: {
          INDEX: {
            PATH: 'marketing/templates/sms',
            NAME: 'lms.marketing.templates.sms',
          },
        },
        EMAIL: {
          INDEX: {
            PATH: 'marketing/templates/email',
            NAME: 'lms.marketing.templates.email',
          },
        },
        TASK: {
          INDEX: {
            PATH: 'marketing/templates/task',
            NAME: 'lms.marketing.templates.task',
          },
        },
        FACEBOOK: {
          INDEX: {
            PATH: 'marketing/templates/facebook',
            NAME: 'lms.marketing.templates.facebook',
          },
        },
      },
      CAMPAIGNS: {
        INDEX: {
          PATH: 'marketing/campaigns',
          NAME: 'lms.marketing.campaigns',
        },
        SENT: {
          PATH: 'marketing/campaigns/sent',
          NAME: 'lms.marketing.campaigns.sent',
        },
        DRAFT: {
          PATH: 'marketing/campaigns/draft',
          NAME: 'lms.marketing.campaigns.draft',
        },
        SCHEDULED: {
          PATH: 'marketing/campaigns/scheduled',
          NAME: 'lms.marketing.campaigns.scheduled',
        },
        CREATE: {
          PATH: 'marketing/campaigns/create',
          NAME: 'lms.marketing.campaigns.create',
        },
        SHOW: {
          PATH: 'marketing/campaigns/:campaignId(\\d+)',
          NAME: 'lms.marketing.campaigns.show',
        },
        EDIT: {
          PATH: 'marketing/campaigns/:campaignId(\\d+)/edit',
          NAME: 'lms.marketing.campaigns.edit',
        },
      },
      FORMS: {
        INDEX: {
          PATH: 'marketing/forms',
          NAME: 'lms.marketing.forms',
        },
        PROFILE: {
          PATH: 'marketing/forms/:formId',
          NAME: 'lms.marketing.forms.profile',
        },
        CREATE: {
          PATH: 'marketing/forms/create',
          NAME: 'lms.marketing.forms.create',
        },
        EDIT: {
          PATH: 'marketing/forms/:formId/edit',
          NAME: 'lms.marketing.forms.edit',
        },
      },
    },
    INTELLIFORMS: {
      INDEX: {
        PATH: 'intelliforms',
        NAME: 'lms.intelliforms.index',
      },
      PACKAGES: {
        INDEX: {
          PATH: 'intelliforms/packages',
          NAME: 'lms.intelliforms.packages',
        },
        CREATE: {
          PATH: 'intelliforms/packages/create',
          NAME: 'lms.intelliforms.packages.create',
        },
        PACKAGE: {
          INDEX: {
            PATH: 'intelliforms/packages/:packageId(\\d+)',
            NAME: 'lms.intelliforms.packages.package.show',
          },
          EDIT: {
            PATH: 'intelliforms/packages/:packageId(\\d+)/edit',
            NAME: 'lms.intelliforms.packages.package.edit',
          },
        },
      },
      DOCUMENTS: {
        INDEX: {
          PATH: 'intelliforms/documents',
          NAME: 'lms.intelliforms.documents',
        },
        CREATE: {
          PATH: 'intelliforms/documents/create',
          NAME: 'lms.intelliforms.documents.create',
        },
        DOCUMENT: {
          EDIT: {
            PATH: 'intelliforms/documents/:documentId(\\d+)/edit',
            NAME: 'lms.intelliforms.documents.document.edit',
          },
        },
      },
    },
    TOOLS: {
      INDEX: {
        PATH: 'tools',
        NAME: 'lms.tools.index',
      },
      EXPORT: {
        INDEX: {
          PATH: 'tools/export',
          NAME: 'lms.tools.export',
        },
        CREATE: {
          PATH: 'tools/export/create',
          NAME: 'lms.tools.export.create',
        },
      },
      DUPLICATES: {
        INDEX: {
          PATH: 'tools/duplicates',
          NAME: 'lms.tools.duplicates',
        },
        CUSTOM: {
          PATH: 'tools/duplicates/custom',
          NAME: 'lms.tools.duplicates/custom',
        },
        MERGE: {
          PATH: 'tools/duplicates/merge',
          NAME: 'lms.tools.duplicates/merge',
        },
      },
    },
  },
}
